/**
 * File download
 * @param url - file url
 * @param fileName - file name
 * @returns void
 * @example
 * fileDownload('https://example.com/image.png', 'image.png');
 * fileDownload('https://example.com/image.png');
 */
export function fileDownload(url: string, fileName?: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName ?? '');
  document.body.append(link);
  link.click();
  link.remove();
}
