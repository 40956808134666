import { useEffect } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { SettingForm, Wrapper } from '@/components';
import { handleFormError } from '@/utils';
import { getSettingsQueryKey, useUpdateSettings, useSettings } from '@/api';
import { settingSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/settings/')({
  component: Pages,
});

function Pages() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { data } = useSettings();

  const methods = useForm<zod.infer<typeof settingSchema>>({
    resolver: zodResolver(settingSchema),
    defaultValues: data,
  });

  const { mutateAsync } = useUpdateSettings({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getSettingsQueryKey(),
        });
      },
    },
  });

  useEffect(() => {
    methods.reset(data);
  }, [methods, data]);

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        data,
      });
      methods.reset(response);
      toast.success(<Trans>Settings updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>Settings</Trans>}>
      <FormProvider {...methods}>
        <SettingForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
