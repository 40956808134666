import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, InstallerForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useInstallerSuspense,
  useUpdateInstaller,
  getInstallersQueryKey,
  getInstallerQueryKey,
  getInstallerSuspenseQueryOptions,
  useSustainabilityActions,
  useInstallerTags,
} from '@/api';
import { installerSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/installers/$installerId/edit',
)({
  loader: async ({ context: { queryClient }, params: { installerId } }) =>
    queryClient.ensureQueryData(
      getInstallerSuspenseQueryOptions(Number(installerId)),
    ),
  component: EditInstaller,
});

function EditInstaller() {
  const installerId = Route.useParams({
    select: ({ installerId }) => Number(installerId),
  });
  const { data: installer } = useInstallerSuspense(installerId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateInstaller({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getInstallersQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getInstallerQueryKey(installerId),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};
  const { data: tagData } = useInstallerTags();
  const { tags = [] } = tagData ?? {};

  const methods = useForm<zod.infer<typeof installerSchema>>({
    resolver: zodResolver(installerSchema),
    defaultValues: {
      name: installer?.name,
      logo: installer.logo?.versions?.large?.url,
      expertise: installer?.expertise,
      location: installer?.location,
      url: installer?.url,
      tagList: installer.tagList.map((tag) => ({
        value: tag,
        label: tag,
      })),
      sustainabilityActionIds: installer?.sustainabilityActions?.map(
        (sustainabilityAction) => sustainabilityAction.id.toString(),
      ),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: installer.id,
        data: {
          ...data,
          logo: data.logo instanceof File ? data.logo : undefined,
          tagList: data.tagList?.map((tag) => tag.value),
        },
      });
      methods.reset({
        name: response.name,
        logo: response.logo?.versions?.large?.url ?? null,
        expertise: response.expertise,
        location: response.location,
        url: response.url,
        tagList: response.tagList.map((tag) => ({
          value: tag,
          label: tag,
        })),
        sustainabilityActionIds: response?.sustainabilityActions?.map(
          (sustainabilityAction) => sustainabilityAction.id.toString(),
        ),
      });
      toast.success(<Trans>Installer updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>Edit installer</Trans>} backLink='/installers'>
      <FormProvider {...methods}>
        <InstallerForm
          tags={tags}
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
