import { useLayoutEffect } from 'react';
import {
  createFileRoute,
  redirect,
  Outlet,
  Link,
  useRouter,
} from '@tanstack/react-router';
import { Sidebar } from 'flowbite-react';
import {
  LayoutDashboard,
  BookOpenText,
  LayoutTemplate,
  TextCursorInput,
  Settings,
  LogOut,
} from 'lucide-react';
import { Trans } from '@lingui/macro';

import { Container } from '@/components';
import { useAuth } from '@/hooks';
import logo from '@/assets/images/logo.svg';
import Group from '@/assets/icons/group.svg?react';
import Home from '@/assets/icons/home.svg?react';
import Leaf from '@/assets/icons/leaf.svg?react';
import Installateur from '@/assets/icons/installateur.svg?react';
import Money from '@/assets/icons/money.svg?react';
import Inkoopactie from '@/assets/icons/inkoopactie.svg?react';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad({ context, location }) {
    if (!context.auth.authenticated) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/sign_in',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Authenticated,
});

function Authenticated() {
  const router = useRouter();
  const navigate = Route.useNavigate();
  const { authenticated, signOut } = useAuth();

  const onLogout = async () => {
    signOut();
    // eslint-disable-next-line promise/no-return-in-finally
    await router.invalidate().finally(async () => {
      return navigate({ to: '/' });
    });
  };

  useLayoutEffect(() => {
    if (!authenticated) {
      router.history.push('/sign_in');
    }
  }, [authenticated, router.history]);

  return (
    <>
      <Sidebar>
        <Sidebar.Logo
          href='#'
          img={logo}
          imgAlt='Logo'
          className='justify-center'
        />
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Link to='/'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={LayoutDashboard}
                >
                  <Trans>Dashboard</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/audiences'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Group as unknown}
                >
                  <Trans>Audiences</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/premises'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Home as unknown}
                >
                  <Trans>Premises</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/pages'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={BookOpenText as unknown}
                >
                  <Trans>Pages</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/content_blocks'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={LayoutTemplate as unknown}
                >
                  <Trans>Blocks</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/sustainability_actions'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Leaf as unknown}
                >
                  <Trans>Sustainability actions</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/installers'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Installateur as unknown}
                >
                  <Trans>Installers</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/grants'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Money as unknown}
                >
                  <Trans>Grants</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/collective_purchases'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={Inkoopactie as unknown}
                >
                  <Trans>Collective purchases</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/registrations'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={TextCursorInput}
                >
                  <Trans>Registrations</Trans>
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/settings'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Settings}>
                  <Trans>Settings</Trans>
                </Sidebar.Item>
              )}
            </Link>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item icon={LogOut} onClick={onLogout}>
              <Trans>Logout</Trans>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <Container>
        <Outlet />
      </Container>
    </>
  );
}
