import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import {
  TextInput,
  Textarea,
  PriceInput,
  Select,
  MultiSelect,
} from '@/components';
import { GrantType, type SustainabilityAction } from '@/api/model';
import type { grantSchema } from '@/schemas';

const grantTypes = {
  [GrantType.subsidy]: msg`Subsidy`,
  [GrantType.loan]: msg`Loan`,
};

export function GrantForm({
  sustainabilityActions,
  onSave,
}: {
  readonly sustainabilityActions: readonly SustainabilityAction[];
  readonly onSave: () => void;
}) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof grantSchema>>();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='title'
        label={_(msg`Title`)}
        placeholder={_(msg`Title`)}
        control={control}
        errorMessage={errors.title?.message}
      />
      <Textarea
        name='description'
        label={_(msg`Description`)}
        placeholder={_(msg`Description`)}
        control={control}
        errorMessage={errors.description?.message}
      />
      <TextInput
        name='deadline'
        label={_(msg`Deadline`)}
        placeholder={_(msg`Deadline`)}
        control={control}
        errorMessage={errors.deadline?.message}
      />
      <PriceInput
        name='amount'
        label={_(msg`Amount`)}
        placeholder={_(msg`Amount`)}
        control={control}
        errorMessage={errors.amount?.message}
      />
      <TextInput
        name='url'
        label={_(msg`Link`)}
        placeholder={_(msg`Link`)}
        control={control}
        errorMessage={errors.url?.message}
        type='url'
      />
      <Select
        name='grantType'
        label={_(msg`Type`)}
        placeholder={_(msg`Type`)}
        control={control}
        options={Object.keys(grantTypes).map((grantType) => ({
          value: grantType,
          label: i18n._(grantTypes[grantType as GrantType]),
        }))}
        errorMessage={errors.grantType?.message}
      />
      <MultiSelect
        name='sustainabilityActionIds'
        label={_(msg`Sustainability actions`)}
        options={sustainabilityActions.map((sustainabilityAction) => ({
          value: sustainabilityAction.id.toString(),
          label: sustainabilityAction.title,
        }))}
        control={control}
        errorMessage={errors.sustainabilityActionIds?.message}
      />
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
