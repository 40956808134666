import { z as zod } from 'zod';

export const pageSchema = zod.object({
  title: zod.string(),
  subtitle: zod.string(),
  image: zod
    .union([zod.string(), zod.instanceof(File), zod.instanceof(Blob)])
    .nullish()
    .optional(),
  removeImage: zod.boolean().optional(),
  content: zod.string(),
  documents: zod
    .array(
      zod.object({
        documentId: zod.number().optional().readonly(),
        name: zod.string(),
        file: zod
          .union([zod.instanceof(File), zod.instanceof(Blob)])
          .optional(),
        fileUrl: zod.string().optional().readonly(),
        fileSize: zod.number().optional().readonly(),
        fileType: zod.string().optional().readonly(),
        _destroy: zod.boolean().optional(),
      }),
    )
    .optional(),
  file: zod.union([zod.instanceof(File), zod.instanceof(Blob)]).optional(),
});
