import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  TextInput,
  MultipleCreatableSelect,
  MultiSelect,
  FileInput,
  Label,
} from '@/components';
import type { Tag, SustainabilityAction } from '@/api/model';
import type { installerSchema } from '@/schemas';

export function InstallerForm({
  tags,
  sustainabilityActions,
  onSave,
}: {
  readonly tags: readonly Tag[];
  readonly sustainabilityActions: readonly SustainabilityAction[];
  readonly onSave: () => void;
}) {
  const { _ } = useLingui();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<zod.infer<typeof installerSchema>>();

  return (
    <>
      <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col gap-4'>
          <TextInput
            name='name'
            label={_(msg`Name`)}
            placeholder={_(msg`Name`)}
            control={control}
            errorMessage={errors.name?.message}
          />
          <TextInput
            name='expertise'
            label={_(msg`Expertise`)}
            placeholder={_(msg`Expertise`)}
            control={control}
            errorMessage={errors.expertise?.message}
          />
          <TextInput
            name='location'
            label={_(msg`Location`)}
            placeholder={_(msg`Location`)}
            control={control}
            errorMessage={errors.location?.message}
          />
          <TextInput
            name='url'
            label={_(msg`Link`)}
            placeholder={_(msg`Link`)}
            control={control}
            errorMessage={errors.url?.message}
            type='url'
          />
          <MultipleCreatableSelect
            name='tagList'
            label={_(msg`Tags`)}
            placeholder={_(msg`Tags`)}
            options={tags.map((tag) => ({
              value: tag.name,
              label: tag.name,
            }))}
            control={control}
            errorMessage={errors.tagList?.message}
          />
          <MultiSelect
            name='sustainabilityActionIds'
            label={_(msg`Sustainability actions`)}
            options={sustainabilityActions.map((sustainabilityAction) => ({
              value: sustainabilityAction.id.toString(),
              label: sustainabilityAction.title,
            }))}
            control={control}
            errorMessage={errors.sustainabilityActionIds?.message}
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label value={_(msg`Logo`)} />
          </div>
          <div className='w-[220px] h-[220px]'>
            <FileInput
              name='logo'
              label={_(msg`Logo`)}
              placeholder={_(msg`Logo`)}
              control={control}
              variant='image'
              maxImageSize='1200x1200'
              errorMessage={errors.logo?.message}
              onRemove={() => {
                setValue('logo', null);
                setValue('removeLogo', true);
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </>
  );
}
