import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { z as zod } from 'zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, CollectivePurchaseForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCreateCollectivePurchase,
  getCollectivePurchasesQueryKey,
  useSustainabilityActions,
  useCollectivePurchaseTags,
} from '@/api';
import { collectivePurchaseSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/collective_purchases/new',
)({
  component: NewCollectivePurchase,
});

function NewCollectivePurchase() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreateCollectivePurchase({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getCollectivePurchasesQueryKey(),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};
  const { data: tagData } = useCollectivePurchaseTags();
  const { tags = [] } = tagData ?? {};

  const methods = useForm<zod.infer<typeof collectivePurchaseSchema>>({
    resolver: zodResolver(collectivePurchaseSchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({
        data: {
          ...data,
          amount: data.amount ?? null,
          tagList: data.tagList?.map((tag) => tag.value),
        },
      });
      methods.reset();
      toast.success(<Trans>Collective purchase created successfully!</Trans>);
      await navigate({ to: '/collective_purchases' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>New collective purchase</Trans>}
      backLink='/collective_purchases'
    >
      <FormProvider {...methods}>
        <CollectivePurchaseForm
          tags={tags}
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
