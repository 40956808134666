/* eslint-disable new-cap */
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  type NodeViewProps,
} from '@tiptap/react';
import TiptapImage from '@tiptap/extension-image';
import { Button } from '@tremor/react';
import { Trash2 } from 'lucide-react';

type ImageComponentProperties = NodeViewProps & {
  readonly node: {
    attrs: {
      'src': string;
      'data-image'?: string;
      'sgid'?: string;
    };
  };
};

function ImageComponent(properties: ImageComponentProperties) {
  const { node, deleteNode } = properties;

  return (
    <NodeViewWrapper className='w-full relative'>
      <Button
        color='red'
        icon={Trash2}
        className='absolute top-2 right-2 cursor-pointer border-0 p-1 z-1'
        onClick={deleteNode}
      />
      <img {...node?.attrs} />
    </NodeViewWrapper>
  );
}

export const Image = TiptapImage.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      'data-image': {
        default: null,
      },
      'sgid': {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent);
  },
});
