import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, SustainabilityActionCategoryForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCreateSustainabilityActionCategory,
  getSustainabilityActionCategoriesQueryKey,
} from '@/api';
import type { SustainabilityActionCategoryCrud } from '@/api/model';
import { sustainabilityActionCategorySchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/sustainability_action_categories/new',
)({
  component: NewSustainabilityActionCategory,
});

function NewSustainabilityActionCategory() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreateSustainabilityActionCategory({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionCategoriesQueryKey(),
        });
      },
    },
  });

  const methods = useForm<SustainabilityActionCategoryCrud>({
    resolver: zodResolver(sustainabilityActionCategorySchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({ data });
      methods.reset();
      toast.success(
        <Trans>Sustainability action category created successfully!</Trans>,
      );
      await navigate({ to: '/sustainability_actions' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>New sustainability action category</Trans>}
      backLink='/sustainability_actions'
    >
      <FormProvider {...methods}>
        <SustainabilityActionCategoryForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
