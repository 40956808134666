import { type PropsWithChildren } from 'react';
import { Card as FCard } from '@tremor/react';

export function Card({
  children,
  title,
  subtitle,
  action,
  padding = true,
}: PropsWithChildren<{
  readonly title?: React.ReactNode;
  readonly subtitle?: string;
  readonly action?: React.ReactNode;
  readonly padding?: boolean;
}>) {
  return (
    <FCard className='p-0'>
      {title && (
        <div className='p-4 border-b'>
          <div className='flex justify-between items-center'>
            <div>
              <h5 className='font-semibold'>{title}</h5>
              {subtitle && (
                <div className='text-sm text-gray-400'>{subtitle}</div>
              )}
            </div>
            {action}
          </div>
        </div>
      )}
      <div className={padding ? 'p-4' : ''}>{children}</div>
    </FCard>
  );
}
