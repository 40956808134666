import { createFileRoute } from '@tanstack/react-router';
import { Trans } from '@lingui/macro';

import { Wrapper, DataTable, audienceColumns } from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useAudiences } from '@/api';

export const Route = createFileRoute('/_authenticated/audiences/')({
  component: Audiences,
});

function Audiences() {
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name', 'title'],
  });
  const { data } = useAudiences(queryParameters, queryOptions);
  const { audiences = [], meta } = data ?? {};

  return (
    <Wrapper header={<Trans>Audiences</Trans>} onSearch={setSearchQuery}>
      <DataTable
        data={audiences}
        columns={audienceColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Wrapper>
  );
}
