/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum RegistrationType {
  advise = 'advise',
  subsidy_alert = 'subsidy_alert',
  collective_purchase_alert = 'collective_purchase_alert',
  collective_purchase_register = 'collective_purchase_register',
  identifier_request = 'identifier_request',
}
