import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Button } from '@tremor/react';
import { Trans } from '@lingui/macro';

import { Wrapper, PageForm } from '@/components';
import { handleFormError } from '@/utils';
import { useCreatePage, getPagesQueryKey } from '@/api';
import { pageSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/pages/new')({
  component: NewPage,
});

function NewPage() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreatePage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getPagesQueryKey(),
        });
      },
    },
  });

  const methods = useForm<zod.infer<typeof pageSchema>>({
    resolver: zodResolver(pageSchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({
        data: {
          ...data,
          image: data.image instanceof File ? data.image : undefined,
        },
      });
      methods.reset();
      toast.success(<Trans>Page created successfully!</Trans>);
      await navigate({ to: '/pages' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>New page</Trans>}
      backLink='/pages'
      extraActions={
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      }
    >
      <FormProvider {...methods}>
        <PageForm />
      </FormProvider>
    </Wrapper>
  );
}
