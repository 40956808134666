/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/naming-convention */
import { mergeAttributes } from '@tiptap/react';
import Italic from '@tiptap/extension-italic';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      setHighlight: () => ReturnType;
      toggleHighlight: () => ReturnType;
      unsetHighlight: () => ReturnType;
    };
  }
}

export const Highlight = Italic.extend({
  name: 'highlight',

  addCommands() {
    return {
      ...this.parent?.(),
      setHighlight:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleHighlight:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetHighlight:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'em.highlight',
        priority: 100,
      },
      {
        tag: 'i.highlight',
        getAttrs: (node) => node.style.fontStyle !== 'normal' && null,
        priority: 100,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'em',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'highlight',
      }),
      0,
    ];
  },
});
