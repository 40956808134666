import { z as zod } from 'zod';

export const settingSchema = zod.object({
  phoneNumber: zod.string(),
  whatsapp: zod.string(),
  email: zod.string(),
  twitter: zod.string().optional(),
  facebook: zod.string().optional(),
  instagram: zod.string().optional(),
  linkedin: zod.string().optional(),
});
