import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t, msg } from '@lingui/macro';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { DataTableColumnHeader } from '../data-table';

import { type Grant, GrantType } from '@/api/model';
import { formatCurrency } from '@/utils';

const grantTypes = {
  [GrantType.subsidy]: msg`Subsidy`,
  [GrantType.loan]: msg`Loan`,
};

const columnHelper = createColumnHelper<Grant>();
export const grantColumns = ({
  onDelete,
}: {
  onDelete: (grant: Grant) => void;
}) => [
  columnHelper.accessor((grant) => grant.title, {
    id: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Title`} />
    ),
    cell: (properties) => (
      <Link
        to='/grants/$grantId/edit'
        params={{
          grantId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((grant) => grant.grantType as string, {
    id: 'grantType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Type`} />
    ),
    cell: (properties) => (
      <div className='flex'>
        {Boolean(properties.getValue()) &&
          i18n._(grantTypes[properties.getValue() as GrantType])}
      </div>
    ),
  }),
  columnHelper.accessor(
    (grant) =>
      grant.amount
        ? formatCurrency(grant.amount.cents, grant.amount.currencyIso)
        : '-',
    {
      id: 'amountCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t`Amount`} />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/grants/$grantId/edit'
              params={{
                grantId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            <Trans>Delete</Trans>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
