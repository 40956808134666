import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, SustainabilityActionForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCreateSustainabilityAction,
  getSustainabilityActionsQueryKey,
  useSustainabilityActionCategories,
} from '@/api';
import type { SustainabilityActionCrud } from '@/api/model';
import { sustainabilityActionSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/sustainability_actions/new',
)({
  component: NewSustainabilityAction,
});

function NewSustainabilityAction() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreateSustainabilityAction({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionsQueryKey(),
        });
      },
    },
  });
  const { data } = useSustainabilityActionCategories();
  const { sustainabilityActionCategories = [] } = data ?? {};

  const methods = useForm<SustainabilityActionCrud>({
    resolver: zodResolver(sustainabilityActionSchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({ data });
      methods.reset();
      toast.success(<Trans>Sustainability action created successfully!</Trans>);
      await navigate({ to: '/sustainability_actions' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>New sustainability action</Trans>}
      backLink='/sustainability_actions'
    >
      <FormProvider {...methods}>
        <SustainabilityActionForm
          sustainabilityActionCategories={sustainabilityActionCategories}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
