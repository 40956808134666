import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from '@lingui/macro';

import { DataTableColumnHeader } from '../data-table';

import { type AdminPremise } from '@/api/model';

const columnHelper = createColumnHelper<AdminPremise>();
export const premiseColumns = () => [
  columnHelper.accessor((premise) => premise.address, {
    id: 'address',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Address`} />
    ),
    cell: (properties) => (
      <Link
        to='/premises/$premiseId'
        params={{
          premiseId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((premise) => premise.identifier, {
    id: 'identifier',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Identifier`} />
    ),
  }),
  columnHelper.accessor((premise) => premise.energyLabel, {
    id: 'energyLabel',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Energy label`} />
    ),
  }),
  columnHelper.accessor(
    (premise) => premise.sustainabilityActionsCount.toString(),
    {
      id: 'completedSustainabilityActionsCount',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t`Completed sustainability actions`}
        />
      ),
      cell: (properties) => (
        <span className='ml-2 self-center font-semibold'>
          {properties.row.original.completedSustainabilityActionsCount}
          {' / '}
          {properties.getValue()}
        </span>
      ),
    },
  ),
];
