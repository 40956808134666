import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t } from '@lingui/macro';

import { DataTableColumnHeader } from '../data-table';

import { type Audience } from '@/api/model';

const columnHelper = createColumnHelper<Audience>();
export const audienceColumns = () => [
  columnHelper.accessor((audience) => audience.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Name`} />
    ),
    cell: (properties) => (
      <Link
        to='/audiences/$audienceId/edit'
        params={{
          audienceId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/audiences/$audienceId/edit'
              params={{
                audienceId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
