import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, SustainabilityActionForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useSustainabilityActionSuspense,
  useUpdateSustainabilityAction,
  getSustainabilityActionQueryKey,
  getSustainabilityActionsQueryKey,
  getSustainabilityActionSuspenseQueryOptions,
  useSustainabilityActionCategories,
} from '@/api';
import type { SustainabilityActionCrud } from '@/api/model';
import { sustainabilityActionSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/sustainability_actions/$sustainabilityActionId/edit',
)({
  loader: async ({
    context: { queryClient },
    params: { sustainabilityActionId },
  }) =>
    queryClient.ensureQueryData(
      getSustainabilityActionSuspenseQueryOptions(
        Number(sustainabilityActionId),
      ),
    ),
  component: EditSustainabilityAction,
});

function EditSustainabilityAction() {
  const sustainabilityActionId = Route.useParams({
    select: ({ sustainabilityActionId }) => Number(sustainabilityActionId),
  });
  const { data: sustainabilityAction } = useSustainabilityActionSuspense(
    sustainabilityActionId,
  );
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateSustainabilityAction({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionsQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionQueryKey(sustainabilityActionId),
        });
      },
    },
  });
  const { data } = useSustainabilityActionCategories();
  const { sustainabilityActionCategories = [] } = data ?? {};

  const methods = useForm<SustainabilityActionCrud>({
    resolver: zodResolver(sustainabilityActionSchema),
    defaultValues: {
      ...sustainabilityAction,
      sustainabilityActionCategoryId:
        sustainabilityAction.sustainabilityActionCategory!.id.toString(),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: sustainabilityAction.id,
        data,
      });
      methods.reset({
        ...response,
        sustainabilityActionCategoryId:
          response.sustainabilityActionCategory!.id.toString(),
      });
      toast.success(<Trans>Sustainability action updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>Edit sustainability action</Trans>}
      backLink='/sustainability_actions'
    >
      <FormProvider {...methods}>
        <SustainabilityActionForm
          sustainabilityActionCategories={sustainabilityActionCategories}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
