import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t } from '@lingui/macro';

import { DataTableColumnHeader } from '../data-table';

import type { SustainabilityActionCategory } from '@/api/model';

const columnHelper = createColumnHelper<SustainabilityActionCategory>();
export const sustainabilityActionCategoryColumns = ({
  onDelete,
}: {
  onDelete: (
    sustainabilityActionCategory: SustainabilityActionCategory,
  ) => void;
}) => [
  columnHelper.accessor(
    (sustainabilityActionCategory) => sustainabilityActionCategory.title,
    {
      id: 'title',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t`Title`} />
      ),
      cell: (properties) => (
        <Link
          to='/sustainability_action_categories/$sustainabilityActionCategoryId/edit'
          params={{
            sustainabilityActionCategoryId:
              properties.row.original.id.toString(),
          }}
          className='flex hover:text-primary'
        >
          <span className='ml-2 self-center font-semibold'>
            {properties.getValue()}
          </span>
        </Link>
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/sustainability_action_categories/$sustainabilityActionCategoryId/edit'
              params={{
                sustainabilityActionCategoryId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            <Trans>Delete</Trans>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
