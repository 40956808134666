/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type {
  AdminPremise,
  AdminPremises200,
  AdminPremisesParams,
  Audience,
  AudienceCrud,
  Audiences200,
  AudiencesParams,
  CollectivePurchase,
  CollectivePurchaseCrud,
  CollectivePurchaseTags200,
  CollectivePurchases200,
  CollectivePurchasesParams,
  ContentBlock,
  ContentBlockCrud,
  ContentBlocks200,
  ContentBlocksParams,
  Errors,
  ExportRegistrationsParams,
  Grant,
  GrantCrud,
  Grants200,
  GrantsParams,
  ImageUpload,
  Installer,
  InstallerCrud,
  InstallerTags200,
  Installers200,
  InstallersParams,
  Page,
  PageCrud,
  Pages200,
  PagesParams,
  Premise,
  PremiseCrud,
  PremiseReport,
  PremiseSearch,
  Profile,
  Registration,
  RegistrationCrud,
  Registrations200,
  RegistrationsParams,
  RelevantCollectivePurchases200,
  RelevantCollectivePurchasesParams,
  RelevantGrants200,
  RelevantGrantsParams,
  RelevantInstallers200,
  RelevantInstallersParams,
  RequestPasswordResetBody,
  RequestVerificationEmailParams,
  ResetPasswordBody,
  SearchPremisesParams,
  Session,
  Sessions200,
  Setting,
  SettingCrud,
  SignInBody,
  SustainabilityAction,
  SustainabilityActionCategories200,
  SustainabilityActionCategoriesParams,
  SustainabilityActionCategory,
  SustainabilityActionCategoryCrud,
  SustainabilityActionCrud,
  SustainabilityActions200,
  SustainabilityActionsParams,
  UpdateEmailBody,
  UpdatePasswordBody,
  UploadImageBody,
  VerifyEmailParams,
} from './model';
import { customInstance } from '../utils/api';
import type { ErrorType, BodyType } from '../utils/api';
import { customFormData } from '../utils/form-data-mutator';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Fetch premises
 */
export const adminPremises = (
  params?: AdminPremisesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AdminPremises200>(
    { url: `/api/admin/premises`, method: 'GET', params, signal },
    options,
  );
};

export const getAdminPremisesQueryKey = (params?: AdminPremisesParams) => {
  return [`/api/admin/premises`, ...(params ? [params] : [])] as const;
};

export const getAdminPremisesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremises>>> = ({
    signal,
  }) => adminPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof adminPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremisesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremises>>
>;
export type AdminPremisesInfiniteQueryError = ErrorType<void>;

export function useAdminPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params: undefined | AdminPremisesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premises
 */

export function useAdminPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremisesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremisesQueryOptions = <
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremises>>> = ({
    signal,
  }) => adminPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremisesQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremises>>
>;
export type AdminPremisesQueryError = ErrorType<void>;

export function useAdminPremises<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params: undefined | AdminPremisesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremises>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremises<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremises>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremises<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premises
 */

export function useAdminPremises<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremisesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremisesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremises>>> = ({
    signal,
  }) => adminPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof adminPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremisesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremises>>
>;
export type AdminPremisesSuspenseQueryError = ErrorType<void>;

export function useAdminPremisesSuspense<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params: undefined | AdminPremisesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesSuspense<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesSuspense<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premises
 */

export function useAdminPremisesSuspense<
  TData = Awaited<ReturnType<typeof adminPremises>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremisesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremisesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremises>>> = ({
    signal,
  }) => adminPremises(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof adminPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremisesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremises>>
>;
export type AdminPremisesSuspenseInfiniteQueryError = ErrorType<void>;

export function useAdminPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params: undefined | AdminPremisesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premises
 */

export function useAdminPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremises>>>,
  TError = ErrorType<void>,
>(
  params?: AdminPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremisesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch premise
 */
export const adminPremise = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AdminPremise>(
    { url: `/api/admin/premises/${id}`, method: 'GET', signal },
    options,
  );
};

export const getAdminPremiseQueryKey = (id: number) => {
  return [`/api/admin/premises/${id}`] as const;
};

export const getAdminPremiseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremise>>> = ({
    signal,
  }) => adminPremise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof adminPremise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremiseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremise>>
>;
export type AdminPremiseInfiniteQueryError = ErrorType<void>;

export function useAdminPremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function useAdminPremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremiseInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremiseQueryOptions = <
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremise>>> = ({
    signal,
  }) => adminPremise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof adminPremise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremiseQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremise>>
>;
export type AdminPremiseQueryError = ErrorType<void>;

export function useAdminPremise<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremise>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremise<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremise>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof adminPremise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremise<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function useAdminPremise<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof adminPremise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremiseQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremiseSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremise>>> = ({
    signal,
  }) => adminPremise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof adminPremise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremiseSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremise>>
>;
export type AdminPremiseSuspenseQueryError = ErrorType<void>;

export function useAdminPremiseSuspense<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseSuspense<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseSuspense<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function useAdminPremiseSuspense<
  TData = Awaited<ReturnType<typeof adminPremise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremiseSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminPremiseSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminPremise>>> = ({
    signal,
  }) => adminPremise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof adminPremise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminPremiseSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminPremise>>
>;
export type AdminPremiseSuspenseInfiniteQueryError = ErrorType<void>;

export function useAdminPremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAdminPremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function useAdminPremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof adminPremise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof adminPremise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminPremiseSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Reset premise identifier
 */
export const resetIdentifier = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AdminPremise>(
    { url: `/api/admin/premises/${id}/reset_identifier`, method: 'POST' },
    options,
  );
};

export const getResetIdentifierMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetIdentifier>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetIdentifier>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetIdentifier>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return resetIdentifier(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetIdentifierMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetIdentifier>>
>;

export type ResetIdentifierMutationError = ErrorType<void>;

/**
 * @summary Reset premise identifier
 */
export const useResetIdentifier = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetIdentifier>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetIdentifier>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getResetIdentifierMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Generate premise identifier file
 */
export const identifierFile = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Blob>(
    {
      url: `/api/admin/premises/${id}/identifier_file.png`,
      method: 'POST',
      responseType: 'blob',
    },
    options,
  );
};

export const getIdentifierFileMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof identifierFile>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof identifierFile>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof identifierFile>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return identifierFile(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IdentifierFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof identifierFile>>
>;

export type IdentifierFileMutationError = ErrorType<void>;

/**
 * @summary Generate premise identifier file
 */
export const useIdentifierFile = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof identifierFile>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof identifierFile>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getIdentifierFileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch audiences
 */
export const audiences = (
  params?: AudiencesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Audiences200>(
    { url: `/api/audiences`, method: 'GET', params, signal },
    options,
  );
};

export const getAudiencesQueryKey = (params?: AudiencesParams) => {
  return [`/api/audiences`, ...(params ? [params] : [])] as const;
};

export const getAudiencesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudiencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audiences>>> = ({
    signal,
  }) => audiences(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof audiences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudiencesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof audiences>>
>;
export type AudiencesInfiniteQueryError = ErrorType<void>;

export function useAudiencesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params: undefined | AudiencesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof audiences>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof audiences>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audiences
 */

export function useAudiencesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudiencesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudiencesQueryOptions = <
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audiences>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudiencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audiences>>> = ({
    signal,
  }) => audiences(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof audiences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudiencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof audiences>>
>;
export type AudiencesQueryError = ErrorType<void>;

export function useAudiences<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params: undefined | AudiencesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audiences>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof audiences>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiences<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audiences>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof audiences>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiences<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audiences>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audiences
 */

export function useAudiences<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audiences>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudiencesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudiencesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudiencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audiences>>> = ({
    signal,
  }) => audiences(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof audiences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudiencesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof audiences>>
>;
export type AudiencesSuspenseQueryError = ErrorType<void>;

export function useAudiencesSuspense<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params: undefined | AudiencesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesSuspense<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesSuspense<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audiences
 */

export function useAudiencesSuspense<
  TData = Awaited<ReturnType<typeof audiences>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudiencesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudiencesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudiencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audiences>>> = ({
    signal,
  }) => audiences(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof audiences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudiencesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof audiences>>
>;
export type AudiencesSuspenseInfiniteQueryError = ErrorType<void>;

export function useAudiencesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params: undefined | AudiencesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudiencesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audiences
 */

export function useAudiencesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audiences>>>,
  TError = ErrorType<void>,
>(
  params?: AudiencesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audiences>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudiencesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch audience
 */
export const audience = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Audience>(
    { url: `/api/audiences/${id}`, method: 'GET', signal },
    options,
  );
};

export const getAudienceQueryKey = (id: number) => {
  return [`/api/audiences/${id}`] as const;
};

export const getAudienceInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudienceQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audience>>> = ({
    signal,
  }) => audience(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof audience>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudienceInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof audience>>
>;
export type AudienceInfiniteQueryError = ErrorType<void>;

export function useAudienceInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof audience>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof audience>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audience
 */

export function useAudienceInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudienceInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudienceQueryOptions = <
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudienceQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audience>>> = ({
    signal,
  }) => audience(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type AudienceQueryResult = NonNullable<
  Awaited<ReturnType<typeof audience>>
>;
export type AudienceQueryError = ErrorType<void>;

export function useAudience<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof audience>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudience<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof audience>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudience<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audience
 */

export function useAudience<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof audience>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudienceQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudienceSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudienceQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audience>>> = ({
    signal,
  }) => audience(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof audience>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudienceSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof audience>>
>;
export type AudienceSuspenseQueryError = ErrorType<void>;

export function useAudienceSuspense<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceSuspense<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceSuspense<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audience
 */

export function useAudienceSuspense<
  TData = Awaited<ReturnType<typeof audience>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudienceSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAudienceSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAudienceQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof audience>>> = ({
    signal,
  }) => audience(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof audience>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AudienceSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof audience>>
>;
export type AudienceSuspenseInfiniteQueryError = ErrorType<void>;

export function useAudienceSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAudienceSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch audience
 */

export function useAudienceSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof audience>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof audience>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAudienceSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update audience
 */
export const updateAudience = (
  id: number,
  audienceCrud: BodyType<AudienceCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Audience>(
    {
      url: `/api/audiences/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: audienceCrud,
    },
    options,
  );
};

export const getUpdateAudienceMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAudience>>,
    TError,
    { id: number; data: BodyType<AudienceCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAudience>>,
  TError,
  { id: number; data: BodyType<AudienceCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAudience>>,
    { id: number; data: BodyType<AudienceCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAudience(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAudienceMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAudience>>
>;
export type UpdateAudienceMutationBody = BodyType<AudienceCrud>;
export type UpdateAudienceMutationError = ErrorType<void | Errors>;

/**
 * @summary Update audience
 */
export const useUpdateAudience = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAudience>>,
    TError,
    { id: number; data: BodyType<AudienceCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAudience>>,
  TError,
  { id: number; data: BodyType<AudienceCrud> },
  TContext
> => {
  const mutationOptions = getUpdateAudienceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch collective purchases
 */
export const collectivePurchases = (
  params?: CollectivePurchasesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CollectivePurchases200>(
    { url: `/api/collective_purchases`, method: 'GET', params, signal },
    options,
  );
};

export const getCollectivePurchasesQueryKey = (
  params?: CollectivePurchasesParams,
) => {
  return [`/api/collective_purchases`, ...(params ? [params] : [])] as const;
};

export const getCollectivePurchasesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchasesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchases>>
  > = ({ signal }) => collectivePurchases(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchasesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchases>>
>;
export type CollectivePurchasesInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params: undefined | CollectivePurchasesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchases
 */

export function useCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchasesInfiniteQueryOptions(
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchasesQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchasesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchases>>
  > = ({ signal }) => collectivePurchases(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchases>>
>;
export type CollectivePurchasesQueryError = ErrorType<void>;

export function useCollectivePurchases<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params: undefined | CollectivePurchasesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchases<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchases<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchases
 */

export function useCollectivePurchases<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchasesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchasesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchasesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchases>>
  > = ({ signal }) => collectivePurchases(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchasesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchases>>
>;
export type CollectivePurchasesSuspenseQueryError = ErrorType<void>;

export function useCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params: undefined | CollectivePurchasesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchases
 */

export function useCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchases>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchasesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchasesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchasesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchases>>
  > = ({ signal }) => collectivePurchases(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchasesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchases>>
>;
export type CollectivePurchasesSuspenseInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params: undefined | CollectivePurchasesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchases
 */

export function useCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchases>>>,
  TError = ErrorType<void>,
>(
  params?: CollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchasesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create collective purchase
 */
export const createCollectivePurchase = (
  collectivePurchaseCrud: BodyType<CollectivePurchaseCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CollectivePurchase>(
    {
      url: `/api/collective_purchases`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: collectivePurchaseCrud,
    },
    options,
  );
};

export const getCreateCollectivePurchaseMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCollectivePurchase>>,
    TError,
    { data: BodyType<CollectivePurchaseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCollectivePurchase>>,
  TError,
  { data: BodyType<CollectivePurchaseCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCollectivePurchase>>,
    { data: BodyType<CollectivePurchaseCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createCollectivePurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCollectivePurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCollectivePurchase>>
>;
export type CreateCollectivePurchaseMutationBody =
  BodyType<CollectivePurchaseCrud>;
export type CreateCollectivePurchaseMutationError = ErrorType<void | Errors>;

/**
 * @summary Create collective purchase
 */
export const useCreateCollectivePurchase = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCollectivePurchase>>,
    TError,
    { data: BodyType<CollectivePurchaseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCollectivePurchase>>,
  TError,
  { data: BodyType<CollectivePurchaseCrud> },
  TContext
> => {
  const mutationOptions = getCreateCollectivePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch collective purchase
 */
export const collectivePurchase = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CollectivePurchase>(
    { url: `/api/collective_purchases/${id}`, method: 'GET', signal },
    options,
  );
};

export const getCollectivePurchaseQueryKey = (id: number) => {
  return [`/api/collective_purchases/${id}`] as const;
};

export const getCollectivePurchaseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCollectivePurchaseQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchase>>
  > = ({ signal }) => collectivePurchase(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchase>>
>;
export type CollectivePurchaseInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchaseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchase>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchase>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase
 */

export function useCollectivePurchaseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCollectivePurchaseQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchase>>
  > = ({ signal }) => collectivePurchase(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchase>>
>;
export type CollectivePurchaseQueryError = ErrorType<void>;

export function useCollectivePurchase<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchase>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchase<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof collectivePurchase>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchase<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase
 */

export function useCollectivePurchase<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCollectivePurchaseQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchase>>
  > = ({ signal }) => collectivePurchase(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchase>>
>;
export type CollectivePurchaseSuspenseQueryError = ErrorType<void>;

export function useCollectivePurchaseSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase
 */

export function useCollectivePurchaseSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchase>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCollectivePurchaseQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchase>>
  > = ({ signal }) => collectivePurchase(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchase>>
>;
export type CollectivePurchaseSuspenseInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchaseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase
 */

export function useCollectivePurchaseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchase>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof collectivePurchase>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseSuspenseInfiniteQueryOptions(
    id,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update collective purchase
 */
export const updateCollectivePurchase = (
  id: number,
  collectivePurchaseCrud: BodyType<CollectivePurchaseCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CollectivePurchase>(
    {
      url: `/api/collective_purchases/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: collectivePurchaseCrud,
    },
    options,
  );
};

export const getUpdateCollectivePurchaseMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCollectivePurchase>>,
    TError,
    { id: number; data: BodyType<CollectivePurchaseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCollectivePurchase>>,
  TError,
  { id: number; data: BodyType<CollectivePurchaseCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCollectivePurchase>>,
    { id: number; data: BodyType<CollectivePurchaseCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateCollectivePurchase(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCollectivePurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCollectivePurchase>>
>;
export type UpdateCollectivePurchaseMutationBody =
  BodyType<CollectivePurchaseCrud>;
export type UpdateCollectivePurchaseMutationError = ErrorType<void | Errors>;

/**
 * @summary Update collective purchase
 */
export const useUpdateCollectivePurchase = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCollectivePurchase>>,
    TError,
    { id: number; data: BodyType<CollectivePurchaseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCollectivePurchase>>,
  TError,
  { id: number; data: BodyType<CollectivePurchaseCrud> },
  TContext
> => {
  const mutationOptions = getUpdateCollectivePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove collective purchase
 */
export const deleteCollectivePurchase = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/collective_purchases/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteCollectivePurchaseMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollectivePurchase>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCollectivePurchase>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCollectivePurchase>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCollectivePurchase(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCollectivePurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCollectivePurchase>>
>;

export type DeleteCollectivePurchaseMutationError = ErrorType<void>;

/**
 * @summary Remove collective purchase
 */
export const useDeleteCollectivePurchase = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollectivePurchase>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCollectivePurchase>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCollectivePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch content blocks
 */
export const contentBlocks = (
  params?: ContentBlocksParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ContentBlocks200>(
    { url: `/api/content_blocks`, method: 'GET', params, signal },
    options,
  );
};

export const getContentBlocksQueryKey = (params?: ContentBlocksParams) => {
  return [`/api/content_blocks`, ...(params ? [params] : [])] as const;
};

export const getContentBlocksInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlocks>>> = ({
    signal,
  }) => contentBlocks(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof contentBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlocksInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlocks>>
>;
export type ContentBlocksInfiniteQueryError = ErrorType<void>;

export function useContentBlocksInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ContentBlocksParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlocks>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlocks>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content blocks
 */

export function useContentBlocksInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlocksInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlocksQueryOptions = <
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlocks>>> = ({
    signal,
  }) => contentBlocks(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof contentBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlocksQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlocks>>
>;
export type ContentBlocksQueryError = ErrorType<void>;

export function useContentBlocks<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params: undefined | ContentBlocksParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlocks>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlocks>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocks<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlocks>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlocks>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocks<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content blocks
 */

export function useContentBlocks<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlocksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlocksSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlocks>>> = ({
    signal,
  }) => contentBlocks(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof contentBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlocksSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlocks>>
>;
export type ContentBlocksSuspenseQueryError = ErrorType<void>;

export function useContentBlocksSuspense<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params: undefined | ContentBlocksParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksSuspense<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksSuspense<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content blocks
 */

export function useContentBlocksSuspense<
  TData = Awaited<ReturnType<typeof contentBlocks>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlocksSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlocksSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlocks>>> = ({
    signal,
  }) => contentBlocks(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof contentBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlocksSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlocks>>
>;
export type ContentBlocksSuspenseInfiniteQueryError = ErrorType<void>;

export function useContentBlocksSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ContentBlocksParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlocksSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content blocks
 */

export function useContentBlocksSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlocks>>>,
  TError = ErrorType<void>,
>(
  params?: ContentBlocksParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlocks>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlocksSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch content block
 */
export const contentBlock = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ContentBlock>(
    { url: `/api/content_blocks/${id}`, method: 'GET', signal },
    options,
  );
};

export const getContentBlockQueryKey = (id: number) => {
  return [`/api/content_blocks/${id}`] as const;
};

export const getContentBlockInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlock>>> = ({
    signal,
  }) => contentBlock(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof contentBlock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlockInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlock>>
>;
export type ContentBlockInfiniteQueryError = ErrorType<void>;

export function useContentBlockInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlock>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlock>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content block
 */

export function useContentBlockInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlockInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlockQueryOptions = <
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlock>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlock>>> = ({
    signal,
  }) => contentBlock(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof contentBlock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlockQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlock>>
>;
export type ContentBlockQueryError = ErrorType<void>;

export function useContentBlock<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlock>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlock>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlock<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlock>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof contentBlock>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlock<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlock>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content block
 */

export function useContentBlock<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof contentBlock>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlockQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlockSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlock>>> = ({
    signal,
  }) => contentBlock(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof contentBlock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlockSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlock>>
>;
export type ContentBlockSuspenseQueryError = ErrorType<void>;

export function useContentBlockSuspense<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockSuspense<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockSuspense<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content block
 */

export function useContentBlockSuspense<
  TData = Awaited<ReturnType<typeof contentBlock>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlockSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContentBlockSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContentBlockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentBlock>>> = ({
    signal,
  }) => contentBlock(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof contentBlock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentBlockSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentBlock>>
>;
export type ContentBlockSuspenseInfiniteQueryError = ErrorType<void>;

export function useContentBlockSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useContentBlockSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch content block
 */

export function useContentBlockSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof contentBlock>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof contentBlock>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getContentBlockSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update content block
 */
export const updateContentBlock = (
  id: number,
  contentBlockCrud: BodyType<ContentBlockCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ContentBlock>(
    {
      url: `/api/content_blocks/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: contentBlockCrud,
    },
    options,
  );
};

export const getUpdateContentBlockMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContentBlock>>,
    TError,
    { id: number; data: BodyType<ContentBlockCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateContentBlock>>,
  TError,
  { id: number; data: BodyType<ContentBlockCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContentBlock>>,
    { id: number; data: BodyType<ContentBlockCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateContentBlock(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateContentBlockMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateContentBlock>>
>;
export type UpdateContentBlockMutationBody = BodyType<ContentBlockCrud>;
export type UpdateContentBlockMutationError = ErrorType<void | Errors>;

/**
 * @summary Update content block
 */
export const useUpdateContentBlock = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContentBlock>>,
    TError,
    { id: number; data: BodyType<ContentBlockCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateContentBlock>>,
  TError,
  { id: number; data: BodyType<ContentBlockCrud> },
  TContext
> => {
  const mutationOptions = getUpdateContentBlockMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch grants
 */
export const grants = (
  params?: GrantsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Grants200>(
    { url: `/api/grants`, method: 'GET', params, signal },
    options,
  );
};

export const getGrantsQueryKey = (params?: GrantsParams) => {
  return [`/api/grants`, ...(params ? [params] : [])] as const;
};

export const getGrantsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grants>>> = ({
    signal,
  }) => grants(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof grants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof grants>>
>;
export type GrantsInfiniteQueryError = ErrorType<void>;

export function useGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params: undefined | GrantsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof grants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof grants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grants
 */

export function useGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantsQueryOptions = <
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grants>>> = ({
    signal,
  }) => grants(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof grants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantsQueryResult = NonNullable<Awaited<ReturnType<typeof grants>>>;
export type GrantsQueryError = ErrorType<void>;

export function useGrants<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params: undefined | GrantsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof grants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrants<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof grants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrants<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grants
 */

export function useGrants<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grants>>> = ({
    signal,
  }) => grants(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof grants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof grants>>
>;
export type GrantsSuspenseQueryError = ErrorType<void>;

export function useGrantsSuspense<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params: undefined | GrantsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsSuspense<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsSuspense<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grants
 */

export function useGrantsSuspense<
  TData = Awaited<ReturnType<typeof grants>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grants>>> = ({
    signal,
  }) => grants(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof grants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof grants>>
>;
export type GrantsSuspenseInfiniteQueryError = ErrorType<void>;

export function useGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params: undefined | GrantsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grants
 */

export function useGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grants>>>,
  TError = ErrorType<void>,
>(
  params?: GrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantsSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create grant
 */
export const createGrant = (
  grantCrud: BodyType<GrantCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Grant>(
    {
      url: `/api/grants`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: grantCrud,
    },
    options,
  );
};

export const getCreateGrantMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGrant>>,
    TError,
    { data: BodyType<GrantCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createGrant>>,
  TError,
  { data: BodyType<GrantCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createGrant>>,
    { data: BodyType<GrantCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createGrant(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGrantMutationResult = NonNullable<
  Awaited<ReturnType<typeof createGrant>>
>;
export type CreateGrantMutationBody = BodyType<GrantCrud>;
export type CreateGrantMutationError = ErrorType<void | Errors>;

/**
 * @summary Create grant
 */
export const useCreateGrant = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGrant>>,
    TError,
    { data: BodyType<GrantCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createGrant>>,
  TError,
  { data: BodyType<GrantCrud> },
  TContext
> => {
  const mutationOptions = getCreateGrantMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch grant
 */
export const grant = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Grant>(
    { url: `/api/grants/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGrantQueryKey = (id: number) => {
  return [`/api/grants/${id}`] as const;
};

export const getGrantInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grant>>> = ({
    signal,
  }) => grant(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof grant>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof grant>>
>;
export type GrantInfiniteQueryError = ErrorType<void>;

export function useGrantInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof grant>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof grant>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grant
 */

export function useGrantInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantQueryOptions = <
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grant>>> = ({
    signal,
  }) => grant(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GrantQueryResult = NonNullable<Awaited<ReturnType<typeof grant>>>;
export type GrantQueryError = ErrorType<void>;

export function useGrant<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof grant>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrant<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof grant>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrant<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grant
 */

export function useGrant<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grant>>> = ({
    signal,
  }) => grant(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof grant>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof grant>>
>;
export type GrantSuspenseQueryError = ErrorType<void>;

export function useGrantSuspense<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantSuspense<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantSuspense<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grant
 */

export function useGrantSuspense<
  TData = Awaited<ReturnType<typeof grant>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof grant>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grant>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGrantQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof grant>>> = ({
    signal,
  }) => grant(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof grant>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GrantSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof grant>>
>;
export type GrantSuspenseInfiniteQueryError = ErrorType<void>;

export function useGrantSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grant>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grant>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGrantSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grant>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch grant
 */

export function useGrantSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof grant>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof grant>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGrantSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update grant
 */
export const updateGrant = (
  id: number,
  grantCrud: BodyType<GrantCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Grant>(
    {
      url: `/api/grants/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: grantCrud,
    },
    options,
  );
};

export const getUpdateGrantMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGrant>>,
    TError,
    { id: number; data: BodyType<GrantCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGrant>>,
  TError,
  { id: number; data: BodyType<GrantCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGrant>>,
    { id: number; data: BodyType<GrantCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateGrant(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGrantMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGrant>>
>;
export type UpdateGrantMutationBody = BodyType<GrantCrud>;
export type UpdateGrantMutationError = ErrorType<void | Errors>;

/**
 * @summary Update grant
 */
export const useUpdateGrant = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGrant>>,
    TError,
    { id: number; data: BodyType<GrantCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateGrant>>,
  TError,
  { id: number; data: BodyType<GrantCrud> },
  TContext
> => {
  const mutationOptions = getUpdateGrantMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove grant
 */
export const deleteGrant = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/grants/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteGrantMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGrant>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGrant>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGrant>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteGrant(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGrantMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGrant>>
>;

export type DeleteGrantMutationError = ErrorType<void>;

/**
 * @summary Remove grant
 */
export const useDeleteGrant = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGrant>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteGrant>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteGrantMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Request verification email
 */
export const requestVerificationEmail = (
  params: RequestVerificationEmailParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/identity/email_verification`, method: 'POST', params },
    options,
  );
};

export const getRequestVerificationEmailMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestVerificationEmail>>,
    TError,
    { params: RequestVerificationEmailParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestVerificationEmail>>,
  TError,
  { params: RequestVerificationEmailParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestVerificationEmail>>,
    { params: RequestVerificationEmailParams }
  > = (props) => {
    const { params } = props ?? {};

    return requestVerificationEmail(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestVerificationEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestVerificationEmail>>
>;

export type RequestVerificationEmailMutationError = ErrorType<void>;

/**
 * @summary Request verification email
 */
export const useRequestVerificationEmail = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestVerificationEmail>>,
    TError,
    { params: RequestVerificationEmailParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestVerificationEmail>>,
  TError,
  { params: RequestVerificationEmailParams },
  TContext
> => {
  const mutationOptions = getRequestVerificationEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Verify email
 */
export const verifyEmail = (
  params: VerifyEmailParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/identity/email_verification`, method: 'GET', params, signal },
    options,
  );
};

export const getVerifyEmailQueryKey = (params: VerifyEmailParams) => {
  return [
    `/api/identity/email_verification`,
    ...(params ? [params] : []),
  ] as const;
};

export const getVerifyEmailInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyEmailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyEmail>>> = ({
    signal,
  }) => verifyEmail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyEmailInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyEmail>>
>;
export type VerifyEmailInfiniteQueryError = ErrorType<void>;

export function useVerifyEmailInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyEmail>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyEmail>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Verify email
 */

export function useVerifyEmailInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyEmailInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyEmailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyEmail>>> = ({
    signal,
  }) => verifyEmail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyEmail>>
>;
export type VerifyEmailQueryError = ErrorType<void>;

export function useVerifyEmail<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyEmail>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmail<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyEmail>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmail<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Verify email
 */

export function useVerifyEmail<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyEmailQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyEmailSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyEmailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyEmail>>> = ({
    signal,
  }) => verifyEmail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyEmailSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyEmail>>
>;
export type VerifyEmailSuspenseQueryError = ErrorType<void>;

export function useVerifyEmailSuspense<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailSuspense<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailSuspense<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Verify email
 */

export function useVerifyEmailSuspense<
  TData = Awaited<ReturnType<typeof verifyEmail>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyEmailSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyEmailSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyEmailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyEmail>>> = ({
    signal,
  }) => verifyEmail(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyEmailSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyEmail>>
>;
export type VerifyEmailSuspenseInfiniteQueryError = ErrorType<void>;

export function useVerifyEmailSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyEmailSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Verify email
 */

export function useVerifyEmailSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof verifyEmail>>>,
  TError = ErrorType<void>,
>(
  params: VerifyEmailParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof verifyEmail>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyEmailSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update email
 */
export const updateEmail = (
  updateEmailBody: BodyType<UpdateEmailBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/identity/email`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateEmailBody,
    },
    options,
  );
};

export const getUpdateEmailMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEmail>>,
    TError,
    { data: BodyType<UpdateEmailBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEmail>>,
  TError,
  { data: BodyType<UpdateEmailBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEmail>>,
    { data: BodyType<UpdateEmailBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updateEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateEmail>>
>;
export type UpdateEmailMutationBody = BodyType<UpdateEmailBody>;
export type UpdateEmailMutationError = ErrorType<void>;

/**
 * @summary Update email
 */
export const useUpdateEmail = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEmail>>,
    TError,
    { data: BodyType<UpdateEmailBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEmail>>,
  TError,
  { data: BodyType<UpdateEmailBody> },
  TContext
> => {
  const mutationOptions = getUpdateEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Request password reset email
 */
export const requestPasswordReset = (
  requestPasswordResetBody: BodyType<RequestPasswordResetBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/identity/password_reset`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestPasswordResetBody,
    },
    options,
  );
};

export const getRequestPasswordResetMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestPasswordReset>>,
    TError,
    { data: BodyType<RequestPasswordResetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestPasswordReset>>,
  TError,
  { data: BodyType<RequestPasswordResetBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestPasswordReset>>,
    { data: BodyType<RequestPasswordResetBody> }
  > = (props) => {
    const { data } = props ?? {};

    return requestPasswordReset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestPasswordResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestPasswordReset>>
>;
export type RequestPasswordResetMutationBody =
  BodyType<RequestPasswordResetBody>;
export type RequestPasswordResetMutationError = ErrorType<void>;

/**
 * @summary Request password reset email
 */
export const useRequestPasswordReset = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestPasswordReset>>,
    TError,
    { data: BodyType<RequestPasswordResetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestPasswordReset>>,
  TError,
  { data: BodyType<RequestPasswordResetBody> },
  TContext
> => {
  const mutationOptions = getRequestPasswordResetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Reset password
 */
export const resetPassword = (
  resetPasswordBody: BodyType<ResetPasswordBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/identity/password_reset`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: resetPasswordBody,
    },
    options,
  );
};

export const getResetPasswordMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: BodyType<ResetPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { data: BodyType<ResetPasswordBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: BodyType<ResetPasswordBody> }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = BodyType<ResetPasswordBody>;
export type ResetPasswordMutationError = ErrorType<void>;

/**
 * @summary Reset password
 */
export const useResetPassword = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: BodyType<ResetPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { data: BodyType<ResetPasswordBody> },
  TContext
> => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update password
 */
export const updatePassword = (
  updatePasswordBody: BodyType<UpdatePasswordBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/identity/password`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updatePasswordBody,
    },
    options,
  );
};

export const getUpdatePasswordMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: BodyType<UpdatePasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePassword>>,
  TError,
  { data: BodyType<UpdatePasswordBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePassword>>,
    { data: BodyType<UpdatePasswordBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updatePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePassword>>
>;
export type UpdatePasswordMutationBody = BodyType<UpdatePasswordBody>;
export type UpdatePasswordMutationError = ErrorType<void>;

/**
 * @summary Update password
 */
export const useUpdatePassword = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: BodyType<UpdatePasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePassword>>,
  TError,
  { data: BodyType<UpdatePasswordBody> },
  TContext
> => {
  const mutationOptions = getUpdatePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch profile
 */
export const profile = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Profile>(
    { url: `/api/identity/profile`, method: 'GET', signal },
    options,
  );
};

export const getProfileQueryKey = () => {
  return [`/api/identity/profile`] as const;
};

export const getProfileInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileInfiniteQueryError = ErrorType<void>;

export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileQueryError = ErrorType<void>;

export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileSuspenseQueryError = ErrorType<void>;

export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileSuspenseInfiniteQueryError = ErrorType<void>;

export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Upload image
 */
export const uploadImage = (
  uploadImageBody: BodyType<UploadImageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = customFormData(uploadImageBody);
  return customInstance<ImageUpload>(
    {
      url: `/api/images`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUploadImageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadImage>>,
    { data: BodyType<UploadImageBody> }
  > = (props) => {
    const { data } = props ?? {};

    return uploadImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadImage>>
>;
export type UploadImageMutationBody = BodyType<UploadImageBody>;
export type UploadImageMutationError = ErrorType<void>;

/**
 * @summary Upload image
 */
export const useUploadImage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const mutationOptions = getUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch installers
 */
export const installers = (
  params?: InstallersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Installers200>(
    { url: `/api/installers`, method: 'GET', params, signal },
    options,
  );
};

export const getInstallersQueryKey = (params?: InstallersParams) => {
  return [`/api/installers`, ...(params ? [params] : [])] as const;
};

export const getInstallersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installers>>> = ({
    signal,
  }) => installers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installers>>
>;
export type InstallersInfiniteQueryError = ErrorType<void>;

export function useInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params: undefined | InstallersParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof installers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof installers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installers
 */

export function useInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallersQueryOptions = <
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installers>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installers>>> = ({
    signal,
  }) => installers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof installers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallersQueryResult = NonNullable<
  Awaited<ReturnType<typeof installers>>
>;
export type InstallersQueryError = ErrorType<void>;

export function useInstallers<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params: undefined | InstallersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installers>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof installers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallers<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installers>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof installers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallers<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installers>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installers
 */

export function useInstallers<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installers>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installers>>> = ({
    signal,
  }) => installers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof installers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof installers>>
>;
export type InstallersSuspenseQueryError = ErrorType<void>;

export function useInstallersSuspense<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params: undefined | InstallersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersSuspense<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersSuspense<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installers
 */

export function useInstallersSuspense<
  TData = Awaited<ReturnType<typeof installers>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installers>>> = ({
    signal,
  }) => installers(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installers>>
>;
export type InstallersSuspenseInfiniteQueryError = ErrorType<void>;

export function useInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params: undefined | InstallersParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installers
 */

export function useInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installers>>>,
  TError = ErrorType<void>,
>(
  params?: InstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallersSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create installer
 */
export const createInstaller = (
  installerCrud: BodyType<InstallerCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = customFormData(installerCrud);
  return customInstance<Installer>(
    {
      url: `/api/installers`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getCreateInstallerMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInstaller>>,
    TError,
    { data: BodyType<InstallerCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInstaller>>,
  TError,
  { data: BodyType<InstallerCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInstaller>>,
    { data: BodyType<InstallerCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createInstaller(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInstallerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInstaller>>
>;
export type CreateInstallerMutationBody = BodyType<InstallerCrud>;
export type CreateInstallerMutationError = ErrorType<void | Errors>;

/**
 * @summary Create installer
 */
export const useCreateInstaller = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInstaller>>,
    TError,
    { data: BodyType<InstallerCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInstaller>>,
  TError,
  { data: BodyType<InstallerCrud> },
  TContext
> => {
  const mutationOptions = getCreateInstallerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch installer
 */
export const installer = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Installer>(
    { url: `/api/installers/${id}`, method: 'GET', signal },
    options,
  );
};

export const getInstallerQueryKey = (id: number) => {
  return [`/api/installers/${id}`] as const;
};

export const getInstallerInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installer>>> = ({
    signal,
  }) => installer(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installer>>
>;
export type InstallerInfiniteQueryError = ErrorType<void>;

export function useInstallerInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof installer>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof installer>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer
 */

export function useInstallerInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerQueryOptions = <
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installer>>> = ({
    signal,
  }) => installer(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type InstallerQueryResult = NonNullable<
  Awaited<ReturnType<typeof installer>>
>;
export type InstallerQueryError = ErrorType<void>;

export function useInstaller<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof installer>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstaller<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof installer>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstaller<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer
 */

export function useInstaller<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof installer>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installer>>> = ({
    signal,
  }) => installer(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof installer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof installer>>
>;
export type InstallerSuspenseQueryError = ErrorType<void>;

export function useInstallerSuspense<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerSuspense<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerSuspense<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer
 */

export function useInstallerSuspense<
  TData = Awaited<ReturnType<typeof installer>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installer>>> = ({
    signal,
  }) => installer(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installer>>
>;
export type InstallerSuspenseInfiniteQueryError = ErrorType<void>;

export function useInstallerSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer
 */

export function useInstallerSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installer>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof installer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update installer
 */
export const updateInstaller = (
  id: number,
  installerCrud: BodyType<InstallerCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = customFormData(installerCrud);
  return customInstance<Installer>(
    {
      url: `/api/installers/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUpdateInstallerMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInstaller>>,
    TError,
    { id: number; data: BodyType<InstallerCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInstaller>>,
  TError,
  { id: number; data: BodyType<InstallerCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInstaller>>,
    { id: number; data: BodyType<InstallerCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateInstaller(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInstallerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInstaller>>
>;
export type UpdateInstallerMutationBody = BodyType<InstallerCrud>;
export type UpdateInstallerMutationError = ErrorType<void | Errors>;

/**
 * @summary Update installer
 */
export const useUpdateInstaller = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInstaller>>,
    TError,
    { id: number; data: BodyType<InstallerCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInstaller>>,
  TError,
  { id: number; data: BodyType<InstallerCrud> },
  TContext
> => {
  const mutationOptions = getUpdateInstallerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove installer
 */
export const deleteInstaller = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/installers/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteInstallerMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInstaller>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInstaller>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInstaller>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteInstaller(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInstallerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInstaller>>
>;

export type DeleteInstallerMutationError = ErrorType<void>;

/**
 * @summary Remove installer
 */
export const useDeleteInstaller = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInstaller>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInstaller>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteInstallerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch pages
 */
export const pages = (
  params?: PagesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Pages200>(
    { url: `/api/pages`, method: 'GET', params, signal },
    options,
  );
};

export const getPagesQueryKey = (params?: PagesParams) => {
  return [`/api/pages`, ...(params ? [params] : [])] as const;
};

export const getPagesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesInfiniteQueryError = ErrorType<void>;

export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesQueryOptions = <
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesQueryResult = NonNullable<Awaited<ReturnType<typeof pages>>>;
export type PagesQueryError = ErrorType<void>;

export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesSuspenseQueryError = ErrorType<void>;

export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesSuspenseInfiniteQueryError = ErrorType<void>;

export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create page
 */
export const createPage = (
  pageCrud: BodyType<PageCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = customFormData(pageCrud);
  return customInstance<Page>(
    {
      url: `/api/pages`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getCreatePageMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPage>>,
    TError,
    { data: BodyType<PageCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPage>>,
  TError,
  { data: BodyType<PageCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPage>>,
    { data: BodyType<PageCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createPage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPage>>
>;
export type CreatePageMutationBody = BodyType<PageCrud>;
export type CreatePageMutationError = ErrorType<void | Errors>;

/**
 * @summary Create page
 */
export const useCreatePage = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPage>>,
    TError,
    { data: BodyType<PageCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPage>>,
  TError,
  { data: BodyType<PageCrud> },
  TContext
> => {
  const mutationOptions = getCreatePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch page
 */
export const page = (
  id: string | number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Page>(
    { url: `/api/pages/${id}`, method: 'GET', signal },
    options,
  );
};

export const getPageQueryKey = (id: string | number) => {
  return [`/api/pages/${id}`] as const;
};

export const getPageInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageInfiniteQueryError = ErrorType<void>;

export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageQueryOptions = <
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type PageQueryResult = NonNullable<Awaited<ReturnType<typeof page>>>;
export type PageQueryError = ErrorType<void>;

export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageSuspenseQueryError = ErrorType<void>;

export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageSuspenseInfiniteQueryError = ErrorType<void>;

export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update page
 */
export const updatePage = (
  id: string | number,
  pageCrud: BodyType<PageCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = customFormData(pageCrud);
  return customInstance<Page>(
    {
      url: `/api/pages/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUpdatePageMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePage>>,
    TError,
    { id: string | number; data: BodyType<PageCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePage>>,
  TError,
  { id: string | number; data: BodyType<PageCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePage>>,
    { id: string | number; data: BodyType<PageCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePage(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePage>>
>;
export type UpdatePageMutationBody = BodyType<PageCrud>;
export type UpdatePageMutationError = ErrorType<void | Errors>;

/**
 * @summary Update page
 */
export const useUpdatePage = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePage>>,
    TError,
    { id: string | number; data: BodyType<PageCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePage>>,
  TError,
  { id: string | number; data: BodyType<PageCrud> },
  TContext
> => {
  const mutationOptions = getUpdatePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove page
 */
export const deletePage = (
  id: string | number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/pages/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeletePageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePage>>,
    TError,
    { id: string | number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePage>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePage>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deletePage(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePage>>
>;

export type DeletePageMutationError = ErrorType<void>;

/**
 * @summary Remove page
 */
export const useDeletePage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePage>>,
    TError,
    { id: string | number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePage>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeletePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search premises
 */
export const searchPremises = (
  params: SearchPremisesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PremiseSearch[]>(
    { url: `/api/premises/search`, method: 'GET', params, signal },
    options,
  );
};

export const getSearchPremisesQueryKey = (params: SearchPremisesParams) => {
  return [`/api/premises/search`, ...(params ? [params] : [])] as const;
};

export const getSearchPremisesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPremises>>> = ({
    signal,
  }) => searchPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchPremisesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchPremises>>
>;
export type SearchPremisesInfiniteQueryError = ErrorType<unknown>;

export function useSearchPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Search premises
 */

export function useSearchPremisesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchPremisesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchPremisesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPremises>>> = ({
    signal,
  }) => searchPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchPremisesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchPremises>>
>;
export type SearchPremisesQueryError = ErrorType<unknown>;

export function useSearchPremises<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchPremises>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremises<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchPremises>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPremises>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremises<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Search premises
 */

export function useSearchPremises<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchPremises>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchPremisesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchPremisesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPremises>>> = ({
    signal,
  }) => searchPremises(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof searchPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchPremisesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchPremises>>
>;
export type SearchPremisesSuspenseQueryError = ErrorType<unknown>;

export function useSearchPremisesSuspense<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesSuspense<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesSuspense<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Search premises
 */

export function useSearchPremisesSuspense<
  TData = Awaited<ReturnType<typeof searchPremises>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchPremisesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchPremisesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchPremisesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPremises>>> = ({
    signal,
  }) => searchPremises(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchPremises>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchPremisesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchPremises>>
>;
export type SearchPremisesSuspenseInfiniteQueryError = ErrorType<unknown>;

export function useSearchPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Search premises
 */

export function useSearchPremisesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchPremises>>>,
  TError = ErrorType<unknown>,
>(
  params: SearchPremisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchPremises>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchPremisesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch premise
 */
export const premise = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Premise>(
    { url: `/api/premises/${id}`, method: 'GET', signal },
    options,
  );
};

export const getPremiseQueryKey = (id: number) => {
  return [`/api/premises/${id}`] as const;
};

export const getPremiseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof premise>>> = ({
    signal,
  }) => premise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof premise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PremiseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof premise>>
>;
export type PremiseInfiniteQueryError = ErrorType<void>;

export function usePremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof premise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof premise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function usePremiseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPremiseInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPremiseQueryOptions = <
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof premise>>> = ({
    signal,
  }) => premise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type PremiseQueryResult = NonNullable<
  Awaited<ReturnType<typeof premise>>
>;
export type PremiseQueryError = ErrorType<void>;

export function usePremise<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof premise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremise<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof premise>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremise<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function usePremise<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof premise>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPremiseQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPremiseSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof premise>>> = ({
    signal,
  }) => premise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof premise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PremiseSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof premise>>
>;
export type PremiseSuspenseQueryError = ErrorType<void>;

export function usePremiseSuspense<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseSuspense<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseSuspense<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function usePremiseSuspense<
  TData = Awaited<ReturnType<typeof premise>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPremiseSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPremiseSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPremiseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof premise>>> = ({
    signal,
  }) => premise(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof premise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PremiseSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof premise>>
>;
export type PremiseSuspenseInfiniteQueryError = ErrorType<void>;

export function usePremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch premise
 */

export function usePremiseSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof premise>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof premise>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPremiseSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update premise
 */
export const updatePremise = (
  identifier: string,
  premiseCrud: BodyType<PremiseCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Premise>(
    {
      url: `/api/premises/${identifier}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: premiseCrud,
    },
    options,
  );
};

export const getUpdatePremiseMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePremise>>,
    TError,
    { identifier: string; data: BodyType<PremiseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePremise>>,
  TError,
  { identifier: string; data: BodyType<PremiseCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePremise>>,
    { identifier: string; data: BodyType<PremiseCrud> }
  > = (props) => {
    const { identifier, data } = props ?? {};

    return updatePremise(identifier, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePremiseMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePremise>>
>;
export type UpdatePremiseMutationBody = BodyType<PremiseCrud>;
export type UpdatePremiseMutationError = ErrorType<void>;

/**
 * @summary Update premise
 */
export const useUpdatePremise = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePremise>>,
    TError,
    { identifier: string; data: BodyType<PremiseCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePremise>>,
  TError,
  { identifier: string; data: BodyType<PremiseCrud> },
  TContext
> => {
  const mutationOptions = getUpdatePremiseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch relevant collective purchases
 */
export const relevantCollectivePurchases = (
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RelevantCollectivePurchases200>(
    {
      url: `/api/premises/${identifier}/relevant_collective_purchases`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getRelevantCollectivePurchasesQueryKey = (
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
) => {
  return [
    `/api/premises/${identifier}/relevant_collective_purchases`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRelevantCollectivePurchasesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRelevantCollectivePurchasesQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>
  > = ({ signal }) =>
    relevantCollectivePurchases(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantCollectivePurchasesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantCollectivePurchases>>
>;
export type RelevantCollectivePurchasesInfiniteQueryError = ErrorType<void>;

export function useRelevantCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantCollectivePurchasesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantCollectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantCollectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant collective purchases
 */

export function useRelevantCollectivePurchasesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantCollectivePurchasesInfiniteQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantCollectivePurchasesQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRelevantCollectivePurchasesQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>
  > = ({ signal }) =>
    relevantCollectivePurchases(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantCollectivePurchasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantCollectivePurchases>>
>;
export type RelevantCollectivePurchasesQueryError = ErrorType<void>;

export function useRelevantCollectivePurchases<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantCollectivePurchasesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantCollectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchases<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantCollectivePurchases>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchases<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant collective purchases
 */

export function useRelevantCollectivePurchases<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantCollectivePurchasesQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantCollectivePurchasesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRelevantCollectivePurchasesQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>
  > = ({ signal }) =>
    relevantCollectivePurchases(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantCollectivePurchasesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantCollectivePurchases>>
>;
export type RelevantCollectivePurchasesSuspenseQueryError = ErrorType<void>;

export function useRelevantCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantCollectivePurchasesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant collective purchases
 */

export function useRelevantCollectivePurchasesSuspense<
  TData = Awaited<ReturnType<typeof relevantCollectivePurchases>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantCollectivePurchasesSuspenseQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantCollectivePurchasesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRelevantCollectivePurchasesQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>
  > = ({ signal }) =>
    relevantCollectivePurchases(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantCollectivePurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantCollectivePurchasesSuspenseInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof relevantCollectivePurchases>>>;
export type RelevantCollectivePurchasesSuspenseInfiniteQueryError =
  ErrorType<void>;

export function useRelevantCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantCollectivePurchasesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant collective purchases
 */

export function useRelevantCollectivePurchasesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantCollectivePurchases>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantCollectivePurchasesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantCollectivePurchases>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getRelevantCollectivePurchasesSuspenseInfiniteQueryOptions(
      identifier,
      params,
      options,
    );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch relevant grants
 */
export const relevantGrants = (
  identifier: string,
  params?: RelevantGrantsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RelevantGrants200>(
    {
      url: `/api/premises/${identifier}/relevant_grants`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getRelevantGrantsQueryKey = (
  identifier: string,
  params?: RelevantGrantsParams,
) => {
  return [
    `/api/premises/${identifier}/relevant_grants`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRelevantGrantsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantGrantsQueryKey(identifier, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof relevantGrants>>> = ({
    signal,
  }) => relevantGrants(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantGrants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantGrantsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantGrants>>
>;
export type RelevantGrantsInfiniteQueryError = ErrorType<void>;

export function useRelevantGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantGrantsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantGrants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantGrants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant grants
 */

export function useRelevantGrantsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantGrantsInfiniteQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantGrantsQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof relevantGrants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantGrantsQueryKey(identifier, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof relevantGrants>>> = ({
    signal,
  }) => relevantGrants(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof relevantGrants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantGrantsQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantGrants>>
>;
export type RelevantGrantsQueryError = ErrorType<void>;

export function useRelevantGrants<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantGrantsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof relevantGrants>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantGrants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrants<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof relevantGrants>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantGrants>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrants<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof relevantGrants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant grants
 */

export function useRelevantGrants<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof relevantGrants>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantGrantsQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantGrantsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantGrantsQueryKey(identifier, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof relevantGrants>>> = ({
    signal,
  }) => relevantGrants(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof relevantGrants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantGrantsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantGrants>>
>;
export type RelevantGrantsSuspenseQueryError = ErrorType<void>;

export function useRelevantGrantsSuspense<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantGrantsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsSuspense<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsSuspense<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant grants
 */

export function useRelevantGrantsSuspense<
  TData = Awaited<ReturnType<typeof relevantGrants>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantGrantsSuspenseQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantGrantsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantGrantsQueryKey(identifier, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof relevantGrants>>> = ({
    signal,
  }) => relevantGrants(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantGrants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantGrantsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantGrants>>
>;
export type RelevantGrantsSuspenseInfiniteQueryError = ErrorType<void>;

export function useRelevantGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantGrantsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant grants
 */

export function useRelevantGrantsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantGrants>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantGrantsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantGrants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantGrantsSuspenseInfiniteQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch relevant installers
 */
export const relevantInstallers = (
  identifier: string,
  params?: RelevantInstallersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RelevantInstallers200>(
    {
      url: `/api/premises/${identifier}/relevant_installers`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getRelevantInstallersQueryKey = (
  identifier: string,
  params?: RelevantInstallersParams,
) => {
  return [
    `/api/premises/${identifier}/relevant_installers`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRelevantInstallersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantInstallersQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantInstallers>>
  > = ({ signal }) =>
    relevantInstallers(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantInstallers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantInstallersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantInstallers>>
>;
export type RelevantInstallersInfiniteQueryError = ErrorType<void>;

export function useRelevantInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantInstallersParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantInstallers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantInstallers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant installers
 */

export function useRelevantInstallersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantInstallersInfiniteQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantInstallersQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantInstallersQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantInstallers>>
  > = ({ signal }) =>
    relevantInstallers(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof relevantInstallers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantInstallersQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantInstallers>>
>;
export type RelevantInstallersQueryError = ErrorType<void>;

export function useRelevantInstallers<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantInstallersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantInstallers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallers<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof relevantInstallers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallers<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant installers
 */

export function useRelevantInstallers<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantInstallersQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantInstallersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantInstallersQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantInstallers>>
  > = ({ signal }) =>
    relevantInstallers(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof relevantInstallers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantInstallersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantInstallers>>
>;
export type RelevantInstallersSuspenseQueryError = ErrorType<void>;

export function useRelevantInstallersSuspense<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantInstallersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersSuspense<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersSuspense<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant installers
 */

export function useRelevantInstallersSuspense<
  TData = Awaited<ReturnType<typeof relevantInstallers>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantInstallersSuspenseQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRelevantInstallersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRelevantInstallersQueryKey(identifier, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof relevantInstallers>>
  > = ({ signal }) =>
    relevantInstallers(identifier, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!identifier,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof relevantInstallers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RelevantInstallersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof relevantInstallers>>
>;
export type RelevantInstallersSuspenseInfiniteQueryError = ErrorType<void>;

export function useRelevantInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params: undefined | RelevantInstallersParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRelevantInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch relevant installers
 */

export function useRelevantInstallersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof relevantInstallers>>>,
  TError = ErrorType<void>,
>(
  identifier: string,
  params?: RelevantInstallersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof relevantInstallers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRelevantInstallersSuspenseInfiniteQueryOptions(
    identifier,
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Report premise
 */
export const reportPremise = (
  identifier: string,
  premiseReport: BodyType<PremiseReport>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Premise>(
    {
      url: `/api/premises/${identifier}/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: premiseReport,
    },
    options,
  );
};

export const getReportPremiseMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reportPremise>>,
    TError,
    { identifier: string; data: BodyType<PremiseReport> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reportPremise>>,
  TError,
  { identifier: string; data: BodyType<PremiseReport> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reportPremise>>,
    { identifier: string; data: BodyType<PremiseReport> }
  > = (props) => {
    const { identifier, data } = props ?? {};

    return reportPremise(identifier, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReportPremiseMutationResult = NonNullable<
  Awaited<ReturnType<typeof reportPremise>>
>;
export type ReportPremiseMutationBody = BodyType<PremiseReport>;
export type ReportPremiseMutationError = ErrorType<void>;

/**
 * @summary Report premise
 */
export const useReportPremise = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reportPremise>>,
    TError,
    { identifier: string; data: BodyType<PremiseReport> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reportPremise>>,
  TError,
  { identifier: string; data: BodyType<PremiseReport> },
  TContext
> => {
  const mutationOptions = getReportPremiseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch registrations
 */
export const registrations = (
  params?: RegistrationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Registrations200>(
    { url: `/api/registrations`, method: 'GET', params, signal },
    options,
  );
};

export const getRegistrationsQueryKey = (params?: RegistrationsParams) => {
  return [`/api/registrations`, ...(params ? [params] : [])] as const;
};

export const getRegistrationsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registrations>>> = ({
    signal,
  }) => registrations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof registrations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrations>>
>;
export type RegistrationsInfiniteQueryError = ErrorType<void>;

export function useRegistrationsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params: undefined | RegistrationsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof registrations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof registrations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registrations
 */

export function useRegistrationsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationsQueryOptions = <
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registrations>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registrations>>> = ({
    signal,
  }) => registrations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof registrations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrations>>
>;
export type RegistrationsQueryError = ErrorType<void>;

export function useRegistrations<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params: undefined | RegistrationsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registrations>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof registrations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrations<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registrations>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof registrations>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrations<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registrations>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registrations
 */

export function useRegistrations<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registrations>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registrations>>> = ({
    signal,
  }) => registrations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof registrations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrations>>
>;
export type RegistrationsSuspenseQueryError = ErrorType<void>;

export function useRegistrationsSuspense<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params: undefined | RegistrationsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsSuspense<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsSuspense<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registrations
 */

export function useRegistrationsSuspense<
  TData = Awaited<ReturnType<typeof registrations>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registrations>>> = ({
    signal,
  }) => registrations(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof registrations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrations>>
>;
export type RegistrationsSuspenseInfiniteQueryError = ErrorType<void>;

export function useRegistrationsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params: undefined | RegistrationsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registrations
 */

export function useRegistrationsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registrations>>>,
  TError = ErrorType<void>,
>(
  params?: RegistrationsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registrations>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationsSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create registration
 */
export const createRegistration = (
  registrationCrud: BodyType<RegistrationCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Registration>(
    {
      url: `/api/registrations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationCrud,
    },
    options,
  );
};

export const getCreateRegistrationMutationOptions = <
  TError = ErrorType<Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRegistration>>,
    TError,
    { data: BodyType<RegistrationCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createRegistration>>,
  TError,
  { data: BodyType<RegistrationCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createRegistration>>,
    { data: BodyType<RegistrationCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createRegistration(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRegistrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRegistration>>
>;
export type CreateRegistrationMutationBody = BodyType<RegistrationCrud>;
export type CreateRegistrationMutationError = ErrorType<Errors>;

/**
 * @summary Create registration
 */
export const useCreateRegistration = <
  TError = ErrorType<Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRegistration>>,
    TError,
    { data: BodyType<RegistrationCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createRegistration>>,
  TError,
  { data: BodyType<RegistrationCrud> },
  TContext
> => {
  const mutationOptions = getCreateRegistrationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch registration
 */
export const registration = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Registration>(
    { url: `/api/registrations/${id}`, method: 'GET', signal },
    options,
  );
};

export const getRegistrationQueryKey = (id: number) => {
  return [`/api/registrations/${id}`] as const;
};

export const getRegistrationInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registration>>> = ({
    signal,
  }) => registration(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof registration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof registration>>
>;
export type RegistrationInfiniteQueryError = ErrorType<void>;

export function useRegistrationInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof registration>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof registration>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registration
 */

export function useRegistrationInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationQueryOptions = <
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registration>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registration>>> = ({
    signal,
  }) => registration(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof registration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationQueryResult = NonNullable<
  Awaited<ReturnType<typeof registration>>
>;
export type RegistrationQueryError = ErrorType<void>;

export function useRegistration<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registration>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof registration>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistration<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registration>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof registration>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistration<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registration>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registration
 */

export function useRegistration<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof registration>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registration>>> = ({
    signal,
  }) => registration(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof registration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof registration>>
>;
export type RegistrationSuspenseQueryError = ErrorType<void>;

export function useRegistrationSuspense<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationSuspense<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationSuspense<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registration
 */

export function useRegistrationSuspense<
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getRegistrationSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegistrationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registration>>> = ({
    signal,
  }) => registration(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof registration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegistrationSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof registration>>
>;
export type RegistrationSuspenseInfiniteQueryError = ErrorType<void>;

export function useRegistrationSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRegistrationSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch registration
 */

export function useRegistrationSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof registration>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof registration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegistrationSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Export registrations
 */
export const exportRegistrations = (
  params?: ExportRegistrationsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<string>(
    { url: `/api/registrations/export.csv`, method: 'POST', params },
    options,
  );
};

export const getExportRegistrationsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportRegistrations>>,
    TError,
    { params?: ExportRegistrationsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportRegistrations>>,
  TError,
  { params?: ExportRegistrationsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportRegistrations>>,
    { params?: ExportRegistrationsParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportRegistrations(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportRegistrationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportRegistrations>>
>;

export type ExportRegistrationsMutationError = ErrorType<void>;

/**
 * @summary Export registrations
 */
export const useExportRegistrations = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportRegistrations>>,
    TError,
    { params?: ExportRegistrationsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportRegistrations>>,
  TError,
  { params?: ExportRegistrationsParams },
  TContext
> => {
  const mutationOptions = getExportRegistrationsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch sessions
 */
export const sessions = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Sessions200>(
    { url: `/api/sessions`, method: 'GET', signal },
    options,
  );
};

export const getSessionsQueryKey = () => {
  return [`/api/sessions`] as const;
};

export const getSessionsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsInfiniteQueryError = ErrorType<void>;

export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsQueryOptions = <
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsQueryError = ErrorType<void>;

export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsSuspenseQueryError = ErrorType<void>;

export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsSuspenseInfiniteQueryError = ErrorType<void>;

export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch session
 */
export const session = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Session>(
    { url: `/api/sessions/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSessionQueryKey = (id: number) => {
  return [`/api/sessions/${id}`] as const;
};

export const getSessionInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionInfiniteQueryError = ErrorType<void>;

export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionQueryOptions = <
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type SessionQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionQueryError = ErrorType<void>;

export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionSuspenseQueryError = ErrorType<void>;

export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionSuspenseInfiniteQueryError = ErrorType<void>;

export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Remove session
 */
export const deleteSession = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/sessions/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteSessionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSession>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSession>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSession>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteSession(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSessionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSession>>
>;

export type DeleteSessionMutationError = ErrorType<void>;

/**
 * @summary Remove session
 */
export const useDeleteSession = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSession>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSession>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteSessionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign in
 */
export const signIn = (
  signInBody: BodyType<SignInBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/sign_in`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: signInBody,
    },
    options,
  );
};

export const getSignInMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: BodyType<SignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: BodyType<SignInBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signIn>>,
    { data: BodyType<SignInBody> }
  > = (props) => {
    const { data } = props ?? {};

    return signIn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof signIn>>
>;
export type SignInMutationBody = BodyType<SignInBody>;
export type SignInMutationError = ErrorType<void>;

/**
 * @summary Sign in
 */
export const useSignIn = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: BodyType<SignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: BodyType<SignInBody> },
  TContext
> => {
  const mutationOptions = getSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign out
 */
export const signOut = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    { url: `/api/sign_out`, method: 'DELETE' },
    options,
  );
};

export const getSignOutMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOut>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signOut>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signOut>>,
    void
  > = () => {
    return signOut(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignOutMutationResult = NonNullable<
  Awaited<ReturnType<typeof signOut>>
>;

export type SignOutMutationError = ErrorType<void>;

/**
 * @summary Sign out
 */
export const useSignOut = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOut>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof signOut>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSignOutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch settings
 */
export const settings = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Setting>(
    { url: `/api/settings`, method: 'GET', signal },
    options,
  );
};

export const getSettingsQueryKey = () => {
  return [`/api/settings`] as const;
};

export const getSettingsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settings>>> = ({
    signal,
  }) => settings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof settings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SettingsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof settings>>
>;
export type SettingsInfiniteQueryError = ErrorType<unknown>;

export function useSettingsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof settings>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof settings>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch settings
 */

export function useSettingsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSettingsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settings>>> = ({
    signal,
  }) => settings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof settings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof settings>>
>;
export type SettingsQueryError = ErrorType<unknown>;

export function useSettings<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof settings>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettings<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof settings>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettings<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch settings
 */

export function useSettings<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSettingsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settings>>> = ({
    signal,
  }) => settings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof settings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SettingsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof settings>>
>;
export type SettingsSuspenseQueryError = ErrorType<unknown>;

export function useSettingsSuspense<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsSuspense<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsSuspense<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch settings
 */

export function useSettingsSuspense<
  TData = Awaited<ReturnType<typeof settings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSettingsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSettingsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof settings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settings>>> = ({
    signal,
  }) => settings(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof settings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SettingsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof settings>>
>;
export type SettingsSuspenseInfiniteQueryError = ErrorType<unknown>;

export function useSettingsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof settings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof settings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSettingsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof settings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch settings
 */

export function useSettingsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof settings>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof settings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSettingsSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update settings
 */
export const updateSettings = (
  settingCrud: BodyType<SettingCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Setting>(
    {
      url: `/api/settings`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: settingCrud,
    },
    options,
  );
};

export const getUpdateSettingsMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSettings>>,
    TError,
    { data: BodyType<SettingCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSettings>>,
  TError,
  { data: BodyType<SettingCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSettings>>,
    { data: BodyType<SettingCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return updateSettings(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSettings>>
>;
export type UpdateSettingsMutationBody = BodyType<SettingCrud>;
export type UpdateSettingsMutationError = ErrorType<void | Errors>;

/**
 * @summary Update settings
 */
export const useUpdateSettings = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSettings>>,
    TError,
    { data: BodyType<SettingCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSettings>>,
  TError,
  { data: BodyType<SettingCrud> },
  TContext
> => {
  const mutationOptions = getUpdateSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch Sustainability action categories
 */
export const sustainabilityActionCategories = (
  params?: SustainabilityActionCategoriesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SustainabilityActionCategories200>(
    {
      url: `/api/sustainability_action_categories`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getSustainabilityActionCategoriesQueryKey = (
  params?: SustainabilityActionCategoriesParams,
) => {
  return [
    `/api/sustainability_action_categories`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSustainabilityActionCategoriesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  > = ({ signal }) =>
    sustainabilityActionCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoriesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategories>>
>;
export type SustainabilityActionCategoriesInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionCategoriesInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionCategoriesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action categories
 */

export function useSustainabilityActionCategoriesInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategoriesInfiniteQueryOptions(
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  > = ({ signal }) =>
    sustainabilityActionCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategories>>
>;
export type SustainabilityActionCategoriesQueryError = ErrorType<void>;

export function useSustainabilityActionCategories<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionCategoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategories<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategories<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action categories
 */

export function useSustainabilityActionCategories<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategoriesQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  > = ({ signal }) =>
    sustainabilityActionCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategories>>
>;
export type SustainabilityActionCategoriesSuspenseQueryError = ErrorType<void>;

export function useSustainabilityActionCategoriesSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionCategoriesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action categories
 */

export function useSustainabilityActionCategoriesSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategories>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategoriesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategoriesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  > = ({ signal }) =>
    sustainabilityActionCategories(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoriesSuspenseInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof sustainabilityActionCategories>>>;
export type SustainabilityActionCategoriesSuspenseInfiniteQueryError =
  ErrorType<void>;

export function useSustainabilityActionCategoriesSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionCategoriesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoriesSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action categories
 */

export function useSustainabilityActionCategoriesSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategories>>
  >,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSustainabilityActionCategoriesSuspenseInfiniteQueryOptions(
      params,
      options,
    );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Sustainability action category
 */
export const createSustainabilityActionCategory = (
  sustainabilityActionCategoryCrud: BodyType<SustainabilityActionCategoryCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SustainabilityActionCategory>(
    {
      url: `/api/sustainability_action_categories`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: sustainabilityActionCategoryCrud,
    },
    options,
  );
};

export const getCreateSustainabilityActionCategoryMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSustainabilityActionCategory>>,
    TError,
    { data: BodyType<SustainabilityActionCategoryCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSustainabilityActionCategory>>,
  TError,
  { data: BodyType<SustainabilityActionCategoryCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSustainabilityActionCategory>>,
    { data: BodyType<SustainabilityActionCategoryCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createSustainabilityActionCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSustainabilityActionCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSustainabilityActionCategory>>
>;
export type CreateSustainabilityActionCategoryMutationBody =
  BodyType<SustainabilityActionCategoryCrud>;
export type CreateSustainabilityActionCategoryMutationError =
  ErrorType<void | Errors>;

/**
 * @summary Create Sustainability action category
 */
export const useCreateSustainabilityActionCategory = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSustainabilityActionCategory>>,
    TError,
    { data: BodyType<SustainabilityActionCategoryCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSustainabilityActionCategory>>,
  TError,
  { data: BodyType<SustainabilityActionCategoryCrud> },
  TContext
> => {
  const mutationOptions =
    getCreateSustainabilityActionCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch Sustainability action category
 */
export const sustainabilityActionCategory = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SustainabilityActionCategory>(
    {
      url: `/api/sustainability_action_categories/${id}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSustainabilityActionCategoryQueryKey = (id: number) => {
  return [`/api/sustainability_action_categories/${id}`] as const;
};

export const getSustainabilityActionCategoryInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  > = ({ signal }) => sustainabilityActionCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoryInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategory>>
>;
export type SustainabilityActionCategoryInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionCategoryInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoryInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategoryInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action category
 */

export function useSustainabilityActionCategoryInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategoryInfiniteQueryOptions(
    id,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  > = ({ signal }) => sustainabilityActionCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategory>>
>;
export type SustainabilityActionCategoryQueryError = ErrorType<void>;

export function useSustainabilityActionCategory<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategory<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActionCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategory<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action category
 */

export function useSustainabilityActionCategory<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategoryQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategorySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  > = ({ signal }) => sustainabilityActionCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategorySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActionCategory>>
>;
export type SustainabilityActionCategorySuspenseQueryError = ErrorType<void>;

export function useSustainabilityActionCategorySuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategorySuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategorySuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action category
 */

export function useSustainabilityActionCategorySuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActionCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionCategorySuspenseQueryOptions(
    id,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionCategorySuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionCategoryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  > = ({ signal }) => sustainabilityActionCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionCategorySuspenseInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof sustainabilityActionCategory>>>;
export type SustainabilityActionCategorySuspenseInfiniteQueryError =
  ErrorType<void>;

export function useSustainabilityActionCategorySuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategorySuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionCategorySuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch Sustainability action category
 */

export function useSustainabilityActionCategorySuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof sustainabilityActionCategory>>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActionCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSustainabilityActionCategorySuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Sustainability action category
 */
export const updateSustainabilityActionCategory = (
  id: number,
  sustainabilityActionCategoryCrud: BodyType<SustainabilityActionCategoryCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SustainabilityActionCategory>(
    {
      url: `/api/sustainability_action_categories/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: sustainabilityActionCategoryCrud,
    },
    options,
  );
};

export const getUpdateSustainabilityActionCategoryMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSustainabilityActionCategory>>,
    TError,
    { id: number; data: BodyType<SustainabilityActionCategoryCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSustainabilityActionCategory>>,
  TError,
  { id: number; data: BodyType<SustainabilityActionCategoryCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSustainabilityActionCategory>>,
    { id: number; data: BodyType<SustainabilityActionCategoryCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateSustainabilityActionCategory(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSustainabilityActionCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSustainabilityActionCategory>>
>;
export type UpdateSustainabilityActionCategoryMutationBody =
  BodyType<SustainabilityActionCategoryCrud>;
export type UpdateSustainabilityActionCategoryMutationError =
  ErrorType<void | Errors>;

/**
 * @summary Update Sustainability action category
 */
export const useUpdateSustainabilityActionCategory = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSustainabilityActionCategory>>,
    TError,
    { id: number; data: BodyType<SustainabilityActionCategoryCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSustainabilityActionCategory>>,
  TError,
  { id: number; data: BodyType<SustainabilityActionCategoryCrud> },
  TContext
> => {
  const mutationOptions =
    getUpdateSustainabilityActionCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove Sustainability action category
 */
export const deleteSustainabilityActionCategory = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/sustainability_action_categories/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteSustainabilityActionCategoryMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteSustainabilityActionCategory(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSustainabilityActionCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>
>;

export type DeleteSustainabilityActionCategoryMutationError = ErrorType<void>;

/**
 * @summary Remove Sustainability action category
 */
export const useDeleteSustainabilityActionCategory = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSustainabilityActionCategory>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getDeleteSustainabilityActionCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch sustainability actions
 */
export const sustainabilityActions = (
  params?: SustainabilityActionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SustainabilityActions200>(
    { url: `/api/sustainability_actions`, method: 'GET', params, signal },
    options,
  );
};

export const getSustainabilityActionsQueryKey = (
  params?: SustainabilityActionsParams,
) => {
  return [`/api/sustainability_actions`, ...(params ? [params] : [])] as const;
};

export const getSustainabilityActionsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActions>>
  > = ({ signal }) => sustainabilityActions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActions>>
>;
export type SustainabilityActionsInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability actions
 */

export function useSustainabilityActionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionsInfiniteQueryOptions(
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionsQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActions>>
  > = ({ signal }) => sustainabilityActions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActions>>
>;
export type SustainabilityActionsQueryError = ErrorType<void>;

export function useSustainabilityActions<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActions<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityActions>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActions<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability actions
 */

export function useSustainabilityActions<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActions>>
  > = ({ signal }) => sustainabilityActions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActions>>
>;
export type SustainabilityActionsSuspenseQueryError = ErrorType<void>;

export function useSustainabilityActionsSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability actions
 */

export function useSustainabilityActionsSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityActions>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityActions>>
  > = ({ signal }) => sustainabilityActions(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityActions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityActions>>
>;
export type SustainabilityActionsSuspenseInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params: undefined | SustainabilityActionsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability actions
 */

export function useSustainabilityActionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityActions>>>,
  TError = ErrorType<void>,
>(
  params?: SustainabilityActionsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityActions>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionsSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create sustainability action
 */
export const createSustainabilityAction = (
  sustainabilityActionCrud: BodyType<SustainabilityActionCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SustainabilityAction>(
    {
      url: `/api/sustainability_actions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: sustainabilityActionCrud,
    },
    options,
  );
};

export const getCreateSustainabilityActionMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSustainabilityAction>>,
    TError,
    { data: BodyType<SustainabilityActionCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSustainabilityAction>>,
  TError,
  { data: BodyType<SustainabilityActionCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSustainabilityAction>>,
    { data: BodyType<SustainabilityActionCrud> }
  > = (props) => {
    const { data } = props ?? {};

    return createSustainabilityAction(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSustainabilityActionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSustainabilityAction>>
>;
export type CreateSustainabilityActionMutationBody =
  BodyType<SustainabilityActionCrud>;
export type CreateSustainabilityActionMutationError = ErrorType<void | Errors>;

/**
 * @summary Create sustainability action
 */
export const useCreateSustainabilityAction = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSustainabilityAction>>,
    TError,
    { data: BodyType<SustainabilityActionCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSustainabilityAction>>,
  TError,
  { data: BodyType<SustainabilityActionCrud> },
  TContext
> => {
  const mutationOptions = getCreateSustainabilityActionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch sustainability action
 */
export const sustainabilityAction = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SustainabilityAction>(
    { url: `/api/sustainability_actions/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSustainabilityActionQueryKey = (id: number) => {
  return [`/api/sustainability_actions/${id}`] as const;
};

export const getSustainabilityActionInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityAction>>
  > = ({ signal }) => sustainabilityAction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityAction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityAction>>
>;
export type SustainabilityActionInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityAction>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityAction>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability action
 */

export function useSustainabilityActionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityAction>>
  > = ({ signal }) => sustainabilityAction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityAction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityAction>>
>;
export type SustainabilityActionQueryError = ErrorType<void>;

export function useSustainabilityAction<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityAction>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityAction<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sustainabilityAction>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityAction<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability action
 */

export function useSustainabilityAction<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityAction>>
  > = ({ signal }) => sustainabilityAction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sustainabilityAction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityAction>>
>;
export type SustainabilityActionSuspenseQueryError = ErrorType<void>;

export function useSustainabilityActionSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability action
 */

export function useSustainabilityActionSuspense<
  TData = Awaited<ReturnType<typeof sustainabilityAction>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSustainabilityActionSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSustainabilityActionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof sustainabilityAction>>
  > = ({ signal }) => sustainabilityAction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sustainabilityAction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SustainabilityActionSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sustainabilityAction>>
>;
export type SustainabilityActionSuspenseInfiniteQueryError = ErrorType<void>;

export function useSustainabilityActionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSustainabilityActionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sustainability action
 */

export function useSustainabilityActionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sustainabilityAction>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof sustainabilityAction>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSustainabilityActionSuspenseInfiniteQueryOptions(
    id,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update sustainability action
 */
export const updateSustainabilityAction = (
  id: number,
  sustainabilityActionCrud: BodyType<SustainabilityActionCrud>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SustainabilityAction>(
    {
      url: `/api/sustainability_actions/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: sustainabilityActionCrud,
    },
    options,
  );
};

export const getUpdateSustainabilityActionMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSustainabilityAction>>,
    TError,
    { id: number; data: BodyType<SustainabilityActionCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSustainabilityAction>>,
  TError,
  { id: number; data: BodyType<SustainabilityActionCrud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSustainabilityAction>>,
    { id: number; data: BodyType<SustainabilityActionCrud> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateSustainabilityAction(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSustainabilityActionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSustainabilityAction>>
>;
export type UpdateSustainabilityActionMutationBody =
  BodyType<SustainabilityActionCrud>;
export type UpdateSustainabilityActionMutationError = ErrorType<void | Errors>;

/**
 * @summary Update sustainability action
 */
export const useUpdateSustainabilityAction = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSustainabilityAction>>,
    TError,
    { id: number; data: BodyType<SustainabilityActionCrud> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSustainabilityAction>>,
  TError,
  { id: number; data: BodyType<SustainabilityActionCrud> },
  TContext
> => {
  const mutationOptions = getUpdateSustainabilityActionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove sustainability action
 */
export const deleteSustainabilityAction = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/sustainability_actions/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteSustainabilityActionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSustainabilityAction>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSustainabilityAction>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSustainabilityAction>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteSustainabilityAction(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSustainabilityActionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSustainabilityAction>>
>;

export type DeleteSustainabilityActionMutationError = ErrorType<void>;

/**
 * @summary Remove sustainability action
 */
export const useDeleteSustainabilityAction = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSustainabilityAction>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSustainabilityAction>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteSustainabilityActionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch installer tags
 */
export const installerTags = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<InstallerTags200>(
    { url: `/api/tags/installers`, method: 'GET', signal },
    options,
  );
};

export const getInstallerTagsQueryKey = () => {
  return [`/api/tags/installers`] as const;
};

export const getInstallerTagsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installerTags>>> = ({
    signal,
  }) => installerTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installerTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerTagsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installerTags>>
>;
export type InstallerTagsInfiniteQueryError = ErrorType<void>;

export function useInstallerTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof installerTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof installerTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer tags
 */

export function useInstallerTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerTagsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof installerTags>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installerTags>>> = ({
    signal,
  }) => installerTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof installerTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof installerTags>>
>;
export type InstallerTagsQueryError = ErrorType<void>;

export function useInstallerTags<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof installerTags>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof installerTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTags<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof installerTags>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof installerTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTags<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof installerTags>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer tags
 */

export function useInstallerTags<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof installerTags>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerTagsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installerTags>>> = ({
    signal,
  }) => installerTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof installerTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerTagsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof installerTags>>
>;
export type InstallerTagsSuspenseQueryError = ErrorType<void>;

export function useInstallerTagsSuspense<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsSuspense<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsSuspense<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer tags
 */

export function useInstallerTagsSuspense<
  TData = Awaited<ReturnType<typeof installerTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerTagsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInstallerTagsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInstallerTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof installerTags>>> = ({
    signal,
  }) => installerTags(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof installerTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstallerTagsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof installerTags>>
>;
export type InstallerTagsSuspenseInfiniteQueryError = ErrorType<void>;

export function useInstallerTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInstallerTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch installer tags
 */

export function useInstallerTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof installerTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof installerTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInstallerTagsSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch collective purchase tags
 */
export const collectivePurchaseTags = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CollectivePurchaseTags200>(
    { url: `/api/tags/collective_purchases`, method: 'GET', signal },
    options,
  );
};

export const getCollectivePurchaseTagsQueryKey = () => {
  return [`/api/tags/collective_purchases`] as const;
};

export const getCollectivePurchaseTagsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchaseTagsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchaseTags>>
  > = ({ signal }) => collectivePurchaseTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchaseTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseTagsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchaseTags>>
>;
export type CollectivePurchaseTagsInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchaseTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof collectivePurchaseTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof collectivePurchaseTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase tags
 */

export function useCollectivePurchaseTagsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseTagsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchaseTagsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchaseTags>>
  > = ({ signal }) => collectivePurchaseTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchaseTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchaseTags>>
>;
export type CollectivePurchaseTagsQueryError = ErrorType<void>;

export function useCollectivePurchaseTags<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof collectivePurchaseTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTags<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof collectivePurchaseTags>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTags<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase tags
 */

export function useCollectivePurchaseTags<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseTagsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchaseTagsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchaseTags>>
  > = ({ signal }) => collectivePurchaseTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof collectivePurchaseTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseTagsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchaseTags>>
>;
export type CollectivePurchaseTagsSuspenseQueryError = ErrorType<void>;

export function useCollectivePurchaseTagsSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase tags
 */

export function useCollectivePurchaseTagsSuspense<
  TData = Awaited<ReturnType<typeof collectivePurchaseTags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCollectivePurchaseTagsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCollectivePurchaseTagsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCollectivePurchaseTagsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof collectivePurchaseTags>>
  > = ({ signal }) => collectivePurchaseTags(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof collectivePurchaseTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CollectivePurchaseTagsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof collectivePurchaseTags>>
>;
export type CollectivePurchaseTagsSuspenseInfiniteQueryError = ErrorType<void>;

export function useCollectivePurchaseTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCollectivePurchaseTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch collective purchase tags
 */

export function useCollectivePurchaseTagsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof collectivePurchaseTags>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof collectivePurchaseTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCollectivePurchaseTagsSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
