import { createFileRoute } from '@tanstack/react-router';
import { Trans } from '@lingui/macro';

import { Wrapper, DataTable, premiseColumns } from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useAdminPremises } from '@/api';

export const Route = createFileRoute('/_authenticated/premises/')({
  component: Premises,
});

function Premises() {
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['identifier', 'address'],
  });
  const { data } = useAdminPremises(queryParameters, queryOptions);
  const { premises = [], meta } = data ?? {};

  return (
    <Wrapper header={<Trans>Premises</Trans>} onSearch={setSearchQuery}>
      <DataTable
        data={premises}
        columns={premiseColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Wrapper>
  );
}
