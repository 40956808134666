import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t } from '@lingui/macro';

import { DataTableColumnHeader } from '../data-table';

import type { SustainabilityAction } from '@/api/model';

const columnHelper = createColumnHelper<SustainabilityAction>();
export const sustainabilityActionColumns = ({
  onDelete,
}: {
  onDelete: (sustainabilityAction: SustainabilityAction) => void;
}) => [
  columnHelper.accessor((sustainabilityAction) => sustainabilityAction.title, {
    id: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Title`} />
    ),
    cell: (properties) => (
      <Link
        to='/sustainability_actions/$sustainabilityActionId/edit'
        params={{
          sustainabilityActionId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor(
    (sustainabilityAction) =>
      sustainabilityAction.sustainabilityActionCategory!.title,
    {
      id: 'sustainabilityActionCategoryTitle',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t`Sustainability action category`}
        />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/sustainability_actions/$sustainabilityActionId/edit'
              params={{
                sustainabilityActionId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            <Trans>Delete</Trans>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
