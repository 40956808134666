/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum AudienceIllustration {
  particulieren = 'particulieren',
  woningcorporatie = 'woningcorporatie',
  vve = 'vve',
  zakelijk = 'zakelijk',
}
