import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import {
  Wrapper,
  DataTable,
  collectivePurchaseColumns,
  DeleteModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import {
  getCollectivePurchasesQueryKey,
  useDeleteCollectivePurchase,
  useCollectivePurchases,
} from '@/api';
import type { CollectivePurchase } from '@/api/model';

export const Route = createFileRoute('/_authenticated/collective_purchases/')({
  component: CollectivePurchases,
});

function CollectivePurchases() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['title'],
  });
  const { data } = useCollectivePurchases(queryParameters, queryOptions);
  const { collectivePurchases = [], meta } = data ?? {};

  const { mutateAsync: deleteCollectivePurchase } = useDeleteCollectivePurchase(
    {
      mutation: {
        async onSuccess() {
          await queryClient.invalidateQueries({
            queryKey: getCollectivePurchasesQueryKey(),
          });
        },
      },
    },
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCollectivePurchase, setSelectedCollectivePurchase] =
    useState<CollectivePurchase>();

  const onDelete = async () => {
    if (!selectedCollectivePurchase) return;

    await deleteCollectivePurchase({ id: selectedCollectivePurchase.id });
    setOpenDeleteModal(false);
    toast.success(<Trans>Collective purchase deleted successfully!</Trans>);
  };

  return (
    <Wrapper
      header={<Trans>Collective purchases</Trans>}
      actions={
        <Link to='/collective_purchases/new'>
          <Button variant='secondary'>
            <Trans>Add new</Trans>
          </Button>
        </Link>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={collectivePurchases}
        columns={collectivePurchaseColumns({
          onDelete(collectivePurchase) {
            setSelectedCollectivePurchase(collectivePurchase);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <DeleteModal
        type={<Trans>Collective purchase</Trans>}
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
