import { useCallback, useRef, type ChangeEvent } from 'react';
import { Button } from 'flowbite-react';
import { Tooltip } from 'react-tooltip';
import {
  Bold,
  Italic,
  Pilcrow,
  Heading1,
  Heading2,
  Heading3,
  List,
  ListOrdered,
  ListCollapse,
  Undo,
  Redo,
  Quote,
  Link,
  ExternalLink,
  Image,
  Youtube,
  SquareLibrary,
  Highlighter,
} from 'lucide-react';
import type { Editor } from '@tiptap/react';
import { Trans } from '@lingui/macro';

import { uploadImage } from '@/api';

export function MenuBar({ editor }: { readonly editor: Editor }) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    for (const file of files) {
      const upload = await uploadImage({ file }); // eslint-disable-line no-await-in-loop
      editor
        .chain()
        .setImage({
          'src': upload.url,
          'data-image': JSON.stringify(upload),
        })
        .focus()
        .run();
    }
  };

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href as string;
    const url = window.prompt('URL', previousUrl); // eslint-disable-line no-alert

    // Cancelled
    if (url === null) {
      return;
    }

    // Empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // Update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const setLinkBlock = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href as string;
    const url = window.prompt('URL', previousUrl); // eslint-disable-line no-alert

    // Cancelled
    if (url === null) {
      return;
    }

    // Empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // Update link
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLinkBlock({ href: url })
      .run();
  }, [editor]);

  const addYoutubeVideo = () => {
    const url = window.prompt('Enter YouTube URL'); // eslint-disable-line no-alert

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      });
    }
  };

  return (
    <Button.Group className='bg-gray-100 w-full p-2'>
      <Button
        size='sm'
        disabled={!editor.can().chain().focus().toggleBold().run()}
        color={editor.isActive('bold') ? 'primary' : 'gray'}
        id='bold'
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <Bold size={18} />
        <Tooltip anchorSelect='#bold'>
          <Trans>Bold</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        color={editor.isActive('italic') ? 'primary' : 'gray'}
        id='italic'
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <Italic size={18} />
        <Tooltip anchorSelect='#italic'>
          <Trans>Italic</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('paragraph') ? 'primary' : 'gray'}
        id='paragraph'
        onClick={() => editor.chain().focus().setParagraph().run()}
      >
        <Pilcrow size={18} />
        <Tooltip anchorSelect='#paragraph'>
          <Trans>Paragraph</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('heading', { level: 1 }) ? 'primary' : 'gray'}
        id='heading1'
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      >
        <Heading1 size={18} />
        <Tooltip anchorSelect='#heading1'>
          <Trans>Heading 1</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('heading', { level: 2 }) ? 'primary' : 'gray'}
        id='heading2'
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        <Heading2 size={18} />
        <Tooltip anchorSelect='#heading2'>
          <Trans>Heading 2</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('heading', { level: 3 }) ? 'primary' : 'gray'}
        id='heading3'
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
      >
        <Heading3 size={18} />
        <Tooltip anchorSelect='#heading3'>
          <Trans>Heading 3</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('blockquote') ? 'primary' : 'gray'}
        id='blockquote'
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
      >
        <Quote size={18} />
        <Tooltip anchorSelect='#blockquote'>
          <Trans>Blockquote</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('highlight') ? 'primary' : 'gray'}
        id='highlight'
        onClick={() => editor.chain().focus().toggleHighlight().run()}
      >
        <Highlighter size={18} />
        <Tooltip anchorSelect='#highlight'>
          <Trans>Highlight</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('textBlock') ? 'primary' : 'gray'}
        id='textBlock'
        onClick={() => editor.chain().focus().toggleTextBlock().run()}
      >
        <SquareLibrary size={18} />
        <Tooltip anchorSelect='#textBlock'>
          <Trans>Text block</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('youtube') ? 'primary' : 'gray'}
        id='youtube'
        onClick={addYoutubeVideo}
      >
        <Youtube size={18} />
        <Tooltip anchorSelect='#youtube'>
          <Trans>YouTube</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('image') ? 'primary' : 'gray'}
        id='image'
        onClick={handleClick}
      >
        <Image size={18} />
        <Tooltip anchorSelect='#image'>
          <Trans>Image</Trans>
        </Tooltip>
        <input
          ref={hiddenFileInput}
          type='file'
          style={{ display: 'none' }}
          accept='image/jpeg,image/png,image/heic,image/heif,image/webp'
          onChange={handleChange}
        />
      </Button>
      <Button
        size='sm'
        color={editor.isActive('link') ? 'primary' : 'gray'}
        id='link'
        onClick={() => {
          editor.isActive('link') // eslint-disable-line @typescript-eslint/no-unused-expressions
            ? editor.chain().focus().unsetLink().run()
            : setLink();
        }}
      >
        <Link size={18} />
        <Tooltip anchorSelect='#link'>
          <Trans>Link</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('linkBlock') ? 'primary' : 'gray'}
        id='linkBlock'
        onClick={() => {
          editor.isActive('linkBlock') // eslint-disable-line @typescript-eslint/no-unused-expressions
            ? editor.chain().focus().unsetLink().run()
            : setLinkBlock();
        }}
      >
        <ExternalLink size={18} />
        <Tooltip anchorSelect='#linkBlock'>
          <Trans>Link block</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('bulletList') ? 'primary' : 'gray'}
        id='bulletList'
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <List size={18} />
        <Tooltip anchorSelect='#bulletList'>
          <Trans>Bullet list</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('orderedList') ? 'primary' : 'gray'}
        id='orderedList'
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <ListOrdered size={18} />
        <Tooltip anchorSelect='#orderedList'>
          <Trans>Ordered list</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color={editor.isActive('details') ? 'primary' : 'gray'}
        id='details'
        onClick={() => editor.chain().focus().setDetails().run()}
      >
        <ListCollapse size={18} />
        <Tooltip anchorSelect='#details'>
          <Trans>Details</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color='gray'
        disabled={!editor.can().chain().focus().undo().run()}
        id='undo'
        onClick={() => editor.chain().focus().undo().run()}
      >
        <Undo size={18} />
        <Tooltip anchorSelect='#undo'>
          <Trans>Undo</Trans>
        </Tooltip>
      </Button>
      <Button
        size='sm'
        color='gray'
        disabled={!editor.can().chain().focus().redo().run()}
        id='redo'
        onClick={() => editor.chain().focus().redo().run()}
      >
        <Redo size={18} />
        <Tooltip anchorSelect='#redo'>
          <Trans>Redo</Trans>
        </Tooltip>
      </Button>
    </Button.Group>
  );
}
