import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import {
  Wrapper,
  DataTable,
  installerColumns,
  DeleteModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import {
  getInstallersQueryKey,
  useDeleteInstaller,
  useInstallers,
} from '@/api';
import type { Installer } from '@/api/model';

export const Route = createFileRoute('/_authenticated/installers/')({
  component: Installers,
});

function Installers() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name', 'expertise', 'location'],
  });
  const { data } = useInstallers(queryParameters, queryOptions);
  const { installers = [], meta } = data ?? {};

  const { mutateAsync: deleteInstaller } = useDeleteInstaller({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getInstallersQueryKey(),
        });
      },
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedInstaller, setSelectedInstaller] = useState<Installer>();

  const onDelete = async () => {
    if (!selectedInstaller) return;

    await deleteInstaller({ id: selectedInstaller.id });
    setOpenDeleteModal(false);
    toast.success(<Trans>Installer deleted successfully!</Trans>);
  };

  return (
    <Wrapper
      header={<Trans>Installers</Trans>}
      actions={
        <Link to='/installers/new'>
          <Button variant='secondary'>
            <Trans>Add new</Trans>
          </Button>
        </Link>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={installers}
        columns={installerColumns({
          onDelete(installer) {
            setSelectedInstaller(installer);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <DeleteModal
        type={<Trans>Installer</Trans>}
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
