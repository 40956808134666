import { type Table } from '@tanstack/react-table';
import { Button } from 'flowbite-react';
import { MoreHorizontal } from 'lucide-react';
import { Trans } from '@lingui/macro';

const ICON_SIZE = 4; // eslint-disable-line @typescript-eslint/naming-convention

export function DataTablePagination<T>({
  table,
}: {
  readonly table: Table<T>;
}) {
  return (
    <div className='flex items-center justify-end space-x-2 py-4'>
      <Button.Group>
        <Button
          color='gray'
          disabled={!table.getCanPreviousPage()}
          className='items-center'
          onClick={() => {
            table.previousPage();
          }}
        >
          <Trans>Previous</Trans>
        </Button>
        {table.getState().pagination.pageIndex + 1 >= 4 && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(0);
            }}
          >
            1
          </Button>
        )}
        {table.getState().pagination.pageIndex + 1 >= 5 && (
          <Button color='gray' className='items-center'>
            <MoreHorizontal className={`h-${ICON_SIZE} w-${ICON_SIZE}`} />
          </Button>
        )}
        {/* 2 pages before */}
        {table.getState().pagination.pageIndex + 1 - 2 > 0 && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(table.getState().pagination.pageIndex - 2);
            }}
          >
            {table.getState().pagination.pageIndex + 1 - 2}
          </Button>
        )}
        {/* 1 page before */}
        {table.getState().pagination.pageIndex + 1 - 1 > 0 && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(table.getState().pagination.pageIndex - 1);
            }}
          >
            {table.getState().pagination.pageIndex + 1 - 1}
          </Button>
        )}
        {/* Current page */}
        <Button color='primary'>
          {table.getState().pagination.pageIndex + 1}
        </Button>
        {/* 1 page after */}
        {table.getState().pagination.pageIndex + 1 + 1 <=
          table?.getPageCount() && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(table.getState().pagination.pageIndex + 1);
            }}
          >
            {table.getState().pagination.pageIndex + 1 + 1}
          </Button>
        )}
        {/* 2 page after */}
        {table.getState().pagination.pageIndex + 1 + 2 <=
          table?.getPageCount() && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(table.getState().pagination.pageIndex + 2);
            }}
          >
            {table.getState().pagination.pageIndex + 1 + 2}
          </Button>
        )}
        {table.getState().pagination.pageIndex + 1 + 2 <
          (table?.getPageCount() ?? 0) - 1 && (
          <Button color='gray'>
            <MoreHorizontal className={`h-${ICON_SIZE} w-${ICON_SIZE}`} />
          </Button>
        )}
        {table.getState().pagination.pageIndex + 1 + 2 <
          table?.getPageCount() && (
          <Button
            color='gray'
            onClick={() => {
              table.setPageIndex(table?.getPageCount());
            }}
          >
            {table?.getPageCount()}
          </Button>
        )}
        <Button
          color='gray'
          disabled={!table.getCanNextPage()}
          className='items-center'
          onClick={() => {
            table.nextPage();
          }}
        >
          <Trans>Next</Trans>
        </Button>
      </Button.Group>
    </div>
  );
}
