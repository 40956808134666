import { z as zod } from 'zod';

export const installerSchema = zod.object({
  name: zod.string(),
  expertise: zod.string(),
  location: zod.string(),
  url: zod
    .string()
    .transform((value) => {
      if (
        value &&
        !value.startsWith('http://') &&
        !value.startsWith('https://')
      ) {
        return `https://${value}`;
      }

      return value;
    })
    .pipe(zod.string().url().optional().or(zod.literal('')))
    .nullish(),
  logo: zod
    .union([zod.string(), zod.instanceof(File), zod.instanceof(Blob)])
    .nullish()
    .optional(),
  removeLogo: zod.boolean().optional(),
  tagList: zod
    .array(
      zod.object({
        value: zod.string(),
        label: zod.string(),
      }),
    )
    .max(3)
    .optional(),
  sustainabilityActionIds: zod.array(zod.string()).optional(),
});
