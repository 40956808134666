import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import {
  Wrapper,
  DataTable,
  sustainabilityActionColumns,
  sustainabilityActionCategoryColumns,
  DeleteModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import {
  getSustainabilityActionsQueryKey,
  getSustainabilityActionCategoriesQueryKey,
  useDeleteSustainabilityAction,
  useDeleteSustainabilityActionCategory,
  useSustainabilityActions,
  useSustainabilityActionCategories,
} from '@/api';
import type {
  SustainabilityAction,
  SustainabilityActionCategory,
} from '@/api/model';

export const Route = createFileRoute('/_authenticated/sustainability_actions/')(
  {
    component: SustainabilityActions,
  },
);

function SustainabilityActions() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const categoryTable = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['title'],
  });
  const { data } = useSustainabilityActions(queryParameters, queryOptions);
  const { sustainabilityActions = [], meta } = data ?? {};
  const { data: categoryData } = useSustainabilityActionCategories(
    queryParameters,
    queryOptions,
  );
  const { sustainabilityActionCategories = [], meta: categoryMeta } =
    categoryData ?? {};

  const { mutateAsync: deleteSustainabilityAction } =
    useDeleteSustainabilityAction({
      mutation: {
        async onSuccess() {
          await queryClient.invalidateQueries({
            queryKey: getSustainabilityActionsQueryKey(),
          });
        },
      },
    });

  const { mutateAsync: deleteSustainabilityActionCategory } =
    useDeleteSustainabilityActionCategory({
      mutation: {
        async onSuccess() {
          await queryClient.invalidateQueries({
            queryKey: getSustainabilityActionCategoriesQueryKey(),
          });
        },
      },
    });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedSustainabilityAction, setSelectedSustainabilityAction] =
    useState<SustainabilityAction>();

  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [
    selectedSustainabilityActionCategory,
    setSelectedSustainabilityActionCategory,
  ] = useState<SustainabilityActionCategory>();

  const onDelete = async () => {
    if (!selectedSustainabilityAction) return;

    await deleteSustainabilityAction({ id: selectedSustainabilityAction.id });
    setOpenDeleteModal(false);
    toast.success(<Trans>Sustainability action deleted successfully!</Trans>);
  };

  const onDeleteCategory = async () => {
    if (!selectedSustainabilityActionCategory) return;

    await deleteSustainabilityActionCategory({
      id: selectedSustainabilityActionCategory.id,
    });
    setOpenDeleteCategoryModal(false);
    toast.success(
      <Trans>Sustainability action category deleted successfully!</Trans>,
    );
  };

  return (
    <Wrapper
      header={<Trans>Sustainability actions</Trans>}
      actions={
        <Link to='/sustainability_actions/new'>
          <Button variant='secondary'>
            <Trans>Add new</Trans>
          </Button>
        </Link>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={sustainabilityActions}
        columns={sustainabilityActionColumns({
          onDelete(sustainabilityAction) {
            setSelectedSustainabilityAction(sustainabilityAction);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <div className='flex content-center mb-4'>
        <h1 className='text-3xl text-gray-900 dark:text-gray-200'>
          <Trans>Sustainability action categories</Trans>
        </h1>
        <div className='flex ml-4'>
          <Link to='/sustainability_action_categories/new'>
            <Button variant='secondary'>
              <Trans>Add new</Trans>
            </Button>
          </Link>
        </div>
      </div>
      <DataTable
        data={sustainabilityActionCategories}
        columns={sustainabilityActionCategoryColumns({
          onDelete(sustainabilityActionCategory) {
            setSelectedSustainabilityActionCategory(
              sustainabilityActionCategory,
            );
            setOpenDeleteCategoryModal(true);
          },
        })}
        pageCount={categoryMeta?.pagination?.pages}
        pagination={categoryTable.pagination}
        sorting={categoryTable.sorting}
        onPaginationChange={categoryTable.setPagination}
        onSortingChange={categoryTable.setSorting}
      />
      <DeleteModal
        type={<Trans>Sustainability action</Trans>}
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
      <DeleteModal
        type={<Trans>Sustainability action category</Trans>}
        show={openDeleteCategoryModal}
        showModal={setOpenDeleteCategoryModal}
        onDelete={onDeleteCategory}
      />
    </Wrapper>
  );
}
