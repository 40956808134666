import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t } from '@lingui/macro';

import { DataTableColumnHeader } from '../data-table';

import type { CollectivePurchase } from '@/api/model';
import { formatCurrency } from '@/utils';

const columnHelper = createColumnHelper<CollectivePurchase>();
export const collectivePurchaseColumns = ({
  onDelete,
}: {
  onDelete: (collectivePurchase: CollectivePurchase) => void;
}) => [
  columnHelper.accessor((collectivePurchase) => collectivePurchase.title, {
    id: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Title`} />
    ),
    cell: (properties) => (
      <Link
        to='/collective_purchases/$collectivePurchaseId/edit'
        params={{
          collectivePurchaseId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor(
    (collectivePurchase) =>
      collectivePurchase.amount
        ? formatCurrency(
            collectivePurchase.amount.cents,
            collectivePurchase.amount.currencyIso,
          )
        : '-',
    {
      id: 'amountCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t`Amount`} />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/collective_purchases/$collectivePurchaseId/edit'
              params={{
                collectivePurchaseId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            <Trans>Delete</Trans>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
