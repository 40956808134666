import type { ReactNode } from 'react';
import { Dialog, DialogPanel, Button } from '@tremor/react';
import { Trans } from '@lingui/macro';

export function DeleteModal({
  type,
  show,
  showModal,
  onDelete,
}: {
  readonly type: ReactNode;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onDelete: () => void;
}) {
  return (
    <Dialog
      open={show}
      onClose={() => {
        showModal(false);
      }}
    >
      <DialogPanel className='overflow-visible'>
        <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
          <Trans>Delete {type}</Trans>
        </h3>
        <p className='mt-2'>
          <Trans>Are you sure you want to delete this {type}?</Trans>
        </p>
        <div className='flex justify-end mt-4'>
          <Button className='w-full' color='red' onClick={onDelete}>
            <Trans>Delete</Trans>
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
