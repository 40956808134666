import type { ReactNode } from 'react';
import { Button } from 'flowbite-react';

export function QuickSelect<T>({
  selected,
  setSelected,
  options,
}: {
  readonly selected: T | undefined;
  readonly setSelected: (value: T | undefined) => void;
  readonly options: Array<{ key: T | undefined; value: string | ReactNode }>;
}) {
  return (
    <Button.Group className='shadow-sm'>
      {options.map(({ key, value }) => (
        <Button
          key={key as string}
          color={key === selected ? 'primary' : 'gray'}
          onClick={() => {
            setSelected(key);
          }}
        >
          {value}
        </Button>
      ))}
    </Button.Group>
  );
}
