/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Youtube from '@tiptap/extension-youtube';
import Details from '@tiptap-pro/extension-details';
import DetailsSummary from '@tiptap-pro/extension-details-summary';
import DetailsContent from '@tiptap-pro/extension-details-content';
import Placeholder from '@tiptap/extension-placeholder';
import FileHandler from '@tiptap-pro/extension-file-handler';

import { MenuBar } from './editor-menu-bar';
import { Highlight, Image, LinkBlock, TextBlock } from './editor-plugins';

import { uploadImage } from '@/api';

type EditorProperties = {
  readonly content?: string;
  readonly onChange?: (content: string) => void;
};

export function Editor({ content, onChange }: EditorProperties) {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        code: false,
        codeBlock: false,
      }),
      Link.configure({
        openOnClick: false,
        autolink: false,
        linkOnPaste: false,
      }),
      LinkBlock.configure({
        openOnClick: false,
        autolink: false,
        linkOnPaste: false,
      }),
      TextBlock,
      Highlight,
      Youtube.configure({
        controls: false,
        modestBranding: true,
      }),
      Details.configure({
        HTMLAttributes: {
          class: 'details',
        },
      }),
      DetailsSummary,
      DetailsContent,
      Placeholder.configure({
        includeChildren: true,
        placeholder({ node }) {
          if (node.type.name === 'detailsSummary') {
            return 'Summary';
          }

          return '';
        },
      }),
      Image,
      FileHandler.configure({
        allowedMimeTypes: [
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/webp',
        ],
        async onDrop(currentEditor, files, pos) {
          for (const file of files) {
            const upload = await uploadImage({ file });
            currentEditor
              .chain()
              .insertContentAt(pos, {
                type: 'image',
                attrs: {
                  'src': upload.url,
                  'data-image': JSON.stringify(upload),
                },
              })
              .focus()
              .run();
          }
        },
        async onPaste(currentEditor, files) {
          for (const file of files) {
            const upload = await uploadImage({ file });
            currentEditor
              .chain()
              .insertContentAt(currentEditor.state.selection.anchor, {
                type: 'image',
                attrs: {
                  'src': upload.url,
                  'data-image': JSON.stringify(upload),
                },
              })
              .focus()
              .run();
          }
        },
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class:
          'prose dark:prose-invert max-w-none focus:outline-none p-4 bg-white dark:bg-gray-800 h-2/3 overflow-y-auto',
      },
    },
  });

  useEffect(() => {
    if (!editor) return;

    editor.on('update', () => {
      if (editor && onChange) {
        onChange(editor.getHTML());
      }
    });
  }, [editor, onChange]);

  if (!editor) return null;

  return (
    <div className='bg-white rounded-lg border border-gray-200'>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
}
