import { z as zod } from 'zod';

export const collectivePurchaseSchema = zod.object({
  title: zod.string(),
  description: zod.string(),
  deadline: zod.string(),
  amount: zod
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .refine((value) => value === null || !Number.isNaN(Number(value)), {
      message: 'Invalid number',
    })
    .transform((value) => (value === null ? null : Number(value)))
    .optional(),
  url: zod
    .string()
    .transform((value) => {
      if (
        value &&
        !value.startsWith('http://') &&
        !value.startsWith('https://')
      ) {
        return `https://${value}`;
      }

      return value;
    })
    .pipe(zod.string().url().optional().or(zod.literal('')))
    .nullish(),
  tagList: zod
    .array(
      zod.object({
        value: zod.string(),
        label: zod.string(),
      }),
    )
    .max(3)
    .optional(),
  sustainabilityActionIds: zod.array(zod.string()).optional(),
});
