import {
  FileInput as FlowbiteFileInput,
  Label,
  type FileInputProps,
} from 'flowbite-react';
import { Button } from '@tremor/react';
import {
  Controller,
  type FieldValues,
  type Control,
  type Path,
} from 'react-hook-form';
import { Trash2 } from 'lucide-react';

export type FileInputProperties<T extends FieldValues> = FileInputProps & {
  readonly control: Control<T>;
  readonly name: Path<T>;
  readonly label?: string;
  readonly errorMessage?: string;
  readonly variant?: 'image' | 'any';
  readonly maxImageSize?: `${number}x${number}`;
  readonly onRemove?: () => void;
};

export function FileInput<T extends FieldValues>({
  control,
  name,
  label,
  errorMessage,
  variant = 'any',
  maxImageSize,
  onRemove,
  ...rest
}: FileInputProperties<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className='flex flex-col h-full'>
          <div className='flex w-full h-full items-center justify-center relative'>
            {field.value && variant === 'image' && onRemove ? (
              <Button
                color='red'
                icon={Trash2}
                className='absolute top-2 right-2 cursor-pointer border-0 p-1 z-1'
                onClick={onRemove}
              />
            ) : null}
            <Label
              htmlFor={name}
              className={`flex min-h-36 w-full h-full p-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${errorMessage ? 'border-red-500 dark:border-red-400' : ''}`}
            >
              {field.value && variant === 'image' ? (
                <img
                  className='w-full max-w-[600px] max-h-[300px] rounded-lg object-contain'
                  src={
                    (field.value as any) instanceof File
                      ? URL.createObjectURL(field.value)
                      : field.value
                  }
                />
              ) : (
                <div className='flex flex-col items-center justify-center pb-6 pt-5 text-center'>
                  <svg
                    className='mb-4 h-8 w-8 text-gray-500 dark:text-gray-400'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 16'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                    />
                  </svg>
                  <p className='mx-2 text-sm text-gray-500 dark:text-gray-400'>
                    <span className='font-semibold'>Click to upload</span> or
                    drag and drop
                  </p>
                  {variant === 'image' && (
                    <p className='m-2 text-xs text-gray-500 dark:text-gray-400'>
                      JPEG, PNG, HEIC/HEIF or WEBP{' '}
                      {maxImageSize ? `(max. ${maxImageSize} px)` : ''}
                    </p>
                  )}
                </div>
              )}
              <FlowbiteFileInput
                id={name}
                name={field.name}
                className='hidden'
                accept={
                  variant === 'image'
                    ? 'image/jpeg,image/png,image/heic,image/heif,image/webp'
                    : undefined
                }
                onChange={(event) => {
                  field.onChange(event.target?.files?.[0]);
                }}
                {...rest}
              />
            </Label>
          </div>
          {errorMessage && (
            <p className='tremor-TextInput-errorMessage text-sm text-red-500 mt-1'>
              {errorMessage}
            </p>
          )}
        </div>
      )}
    />
  );
}
