import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';
import { Trans, t, msg } from '@lingui/macro';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { DataTableColumnHeader } from '../data-table';

import { type ContentBlock, ContentType } from '@/api/model';

const contentTypes = {
  [ContentType.grant_help]: msg`Grant help`,
  [ContentType.grant_stay_informed]: msg`Grant stay informed`,
  [ContentType.collective_purchase_help]: msg`Collective purchase help`,
  [ContentType.collective_purchase_stay_informed]: msg`Collective purchase stay informed`,
  [ContentType.installer_help]: msg`Installer help`,
  [ContentType.installer_stay_informed]: msg`Installer stay informed`,
  [ContentType.premise_identifier]: msg`Premise identifier`,
  [ContentType.advice]: msg`Advice`,
  [ContentType.contact]: msg`Contact`,
  [ContentType.social_media]: msg`Social media`,
};

const columnHelper = createColumnHelper<ContentBlock>();
export const contentBlockColumns = () => [
  columnHelper.accessor((contentBlock) => contentBlock.title, {
    id: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Title`} />
    ),
    cell: (properties) => (
      <Link
        to='/content_blocks/$contentBlockId/edit'
        params={{
          contentBlockId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((contentBlock) => contentBlock.contentType as string, {
    id: 'contentType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Type`} />
    ),
    cell: (properties) => (
      <div className='flex'>
        {Boolean(properties.getValue()) &&
          i18n._(contentTypes[properties.getValue() as ContentType])}
      </div>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/content_blocks/$contentBlockId/edit'
              params={{
                contentBlockId: row.original.id.toString(),
              }}
            >
              <Trans>Edit</Trans>
            </Link>
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
