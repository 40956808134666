import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, InstallerForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCreateInstaller,
  getInstallersQueryKey,
  useSustainabilityActions,
  useInstallerTags,
} from '@/api';
import { installerSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/installers/new')({
  component: NewInstaller,
});

function NewInstaller() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreateInstaller({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getInstallersQueryKey(),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};
  const { data: tagData } = useInstallerTags();
  const { tags = [] } = tagData ?? {};

  const methods = useForm<zod.infer<typeof installerSchema>>({
    resolver: zodResolver(installerSchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({
        data: {
          ...data,
          logo: data.logo instanceof File ? data.logo : undefined,
          tagList: data.tagList?.map((tag) => tag.value),
        },
      });
      methods.reset();
      toast.success(<Trans>Installer created successfully!</Trans>);
      await navigate({ to: '/installers' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>New installer</Trans>} backLink='/installers'>
      <FormProvider {...methods}>
        <InstallerForm
          tags={tags}
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
