import { createFileRoute } from '@tanstack/react-router';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Table } from 'flowbite-react';
import { msg, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { Wrapper, Card } from '@/components';
import {
  getRegistrationQueryOptions,
  getRegistrationSuspenseQueryOptions,
} from '@/api';
import { RegistrationType } from '@/api/model';

export const Route = createFileRoute(
  '/_authenticated/registrations/$registrationId',
)({
  loader: async ({ context: { queryClient }, params: { registrationId } }) =>
    queryClient.ensureQueryData(
      getRegistrationSuspenseQueryOptions(Number(registrationId)),
    ),
  component: ShowRegistration,
});

const registrationTypes = {
  [RegistrationType.advise]: msg`Advise`,
  [RegistrationType.subsidy_alert]: msg`Subsidy alert`,
  [RegistrationType.collective_purchase_alert]: msg`Collective purchase alert`,
  [RegistrationType.collective_purchase_register]: msg`Collective purchase register`,
  [RegistrationType.identifier_request]: msg`Identifier request`,
};

function ShowRegistration() {
  const { registrationId } = Route.useParams();
  const { data: registration } = useSuspenseQuery(
    getRegistrationQueryOptions(Number(registrationId)),
  );

  const renderSustainabilityActions = () => {
    const actions = registration.sustainabilityActions;

    return (
      <Table striped>
        <Table.Body>
          {actions?.length ? (
            actions.map((action, index) => (
              <Table.Row key={action.id ?? index}>
                <Table.Cell>{action.title}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <Trans>No sustainability actions</Trans>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Wrapper header={<Trans>Registration</Trans>} backLink='/registrations'>
      <div className='grid grid-cols-3 gap-4'>
        <div className='col-span-2 flex flex-col gap-4'>
          <Card title={<Trans>General information</Trans>} padding={false}>
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Registration type</Trans>
                  </Table.Cell>
                  <Table.Cell>
                    {i18n._(registrationTypes[registration.registrationType])}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Name</Trans>
                  </Table.Cell>
                  <Table.Cell>{registration.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Email</Trans>
                  </Table.Cell>
                  <Table.Cell>{registration.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Phone number</Trans>
                  </Table.Cell>
                  <Table.Cell>{registration.phoneNumber}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Postcode</Trans>
                  </Table.Cell>
                  <Table.Cell>{registration.postcode}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>House number</Trans>
                  </Table.Cell>
                  <Table.Cell>{registration.houseNumber}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card>
        </div>
        <div className='flex flex-col gap-4'>
          {registration.adviseQuestion && (
            <Card title={<Trans>Advise question</Trans>}>
              {registration.adviseQuestion}
            </Card>
          )}
          {registration.sustainabilityActions?.length ? (
            <Card title={<Trans>Sustainability actions</Trans>} padding={false}>
              {renderSustainabilityActions()}
            </Card>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}
