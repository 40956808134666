import { Suspense, useEffect } from 'react';
import { RouterProvider } from '@tanstack/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { Flowbite } from 'flowbite-react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { router } from './router';

import { useAuth, AuthProvider } from '@/hooks';
import { flowbiteTheme } from '@/theme';
import { defaultLocale, dynamicActivate } from '@/utils';

import '~/index.css';
import '~/application.tailwind.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

function InnerApp() {
  const auth = useAuth();
  return (
    <Suspense>
      <RouterProvider router={router} context={{ auth, queryClient }} />
    </Suspense>
  );
}

export function App() {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(defaultLocale); // eslint-disable-line @typescript-eslint/no-floating-promises
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Flowbite theme={{ theme: flowbiteTheme }}>
            <ToastContainer />
            <InnerApp />
          </Flowbite>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </I18nProvider>
  );
}
