import { createFileRoute } from '@tanstack/react-router';
import { Trans } from '@lingui/macro';

import { Wrapper } from '@/components';
import { useAuth } from '@/hooks';

export const Route = createFileRoute('/_authenticated/')({
  component: Index,
});

function Index() {
  const { profile } = useAuth();

  return (
    <Wrapper
      header={
        <Trans>
          Welcome back, <span className='text-primary'>{profile?.email}!</span>
        </Trans>
      }
    />
  );
}
