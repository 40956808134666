/* eslint-disable @typescript-eslint/naming-convention */
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Column,
  type ColumnDef,
  type PaginationState,
  type SortingState,
  type OnChangeFn,
} from '@tanstack/react-table';
import { Table } from 'flowbite-react';
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';
import { Trans } from '@lingui/macro';

import { DataTablePagination } from './data-table-pagination';

type DataTableProperties<TData, TValue> = {
  readonly columns: Array<ColumnDef<TData, TValue>>;
  readonly data: TData[];
  readonly pageCount?: number;
  readonly pagination?: PaginationState;
  readonly sorting?: SortingState;
  readonly onPaginationChange?: OnChangeFn<PaginationState>;
  readonly onSortingChange?: OnChangeFn<SortingState>;
};

type DataTableColumnHeaderProperties<TData, TValue> = {
  readonly column: Column<TData, TValue>;
  readonly title: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function DataTable<TData>({
  columns,
  data,
  pageCount,
  pagination,
  sorting,
  onPaginationChange,
  onSortingChange,
}: DataTableProperties<TData, string>) {
  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount ?? -1,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: pagination && getPaginationRowModel(),
    getSortedRowModel: sorting && getSortedRowModel(),
    manualPagination: pagination !== undefined,
    manualSorting: sorting !== undefined,
  });

  return (
    <div>
      <div className='rounded-lg border relative shadow-sm'>
        <Table striped className='drop-shadow-none relative z-10'>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Head key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.HeadCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Table.HeadCell>
                );
              })}
            </Table.Head>
          ))}
          <Table.Body className='divide-y'>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  <Trans>No results</Trans>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
}: DataTableColumnHeaderProperties<TData, TValue>) {
  if (!column.getCanSort()) {
    return title;
  }

  return (
    <div
      className='flex items-center space-x-2'
      onClick={() => {
        column.toggleSorting(column.getIsSorted() === 'asc');
      }}
    >
      <span>{title}</span>
      {column.getIsSorted() === 'desc' ? (
        <ArrowDown className='ml-2 h-4 w-4' />
      ) : column.getIsSorted() === 'asc' ? (
        <ArrowUp className='ml-2 h-4 w-4' />
      ) : (
        <ArrowUpDown className='ml-2 h-4 w-4' />
      )}
    </div>
  );
}
