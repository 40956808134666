import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TextInput } from '@/components';
import type { sustainabilityActionCategorySchema } from '@/schemas';

export function SustainabilityActionCategoryForm({
  onSave,
}: {
  readonly onSave: () => void;
}) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof sustainabilityActionCategorySchema>>();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='title'
        label={_(msg`Title`)}
        placeholder={_(msg`Title`)}
        control={control}
        errorMessage={errors.title?.message}
      />
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
