import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, SustainabilityActionCategoryForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useSustainabilityActionCategorySuspense,
  useUpdateSustainabilityActionCategory,
  getSustainabilityActionCategoryQueryKey,
  getSustainabilityActionCategoriesQueryKey,
  getSustainabilityActionCategorySuspenseQueryOptions,
} from '@/api';
import type { SustainabilityActionCategoryCrud } from '@/api/model';
import { sustainabilityActionCategorySchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/sustainability_action_categories/$sustainabilityActionCategoryId/edit',
)({
  loader: async ({
    context: { queryClient },
    params: { sustainabilityActionCategoryId },
  }) =>
    queryClient.ensureQueryData(
      getSustainabilityActionCategorySuspenseQueryOptions(
        Number(sustainabilityActionCategoryId),
      ),
    ),
  component: EditSustainabilityActionCategory,
});

function EditSustainabilityActionCategory() {
  const sustainabilityActionCategoryId = Route.useParams({
    select: ({ sustainabilityActionCategoryId }) =>
      Number(sustainabilityActionCategoryId),
  });
  const { data: sustainabilityActionCategory } =
    useSustainabilityActionCategorySuspense(sustainabilityActionCategoryId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateSustainabilityActionCategory({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionCategoriesQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getSustainabilityActionCategoryQueryKey(
            sustainabilityActionCategoryId,
          ),
        });
      },
    },
  });

  const methods = useForm<SustainabilityActionCategoryCrud>({
    resolver: zodResolver(sustainabilityActionCategorySchema),
    defaultValues: sustainabilityActionCategory,
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: sustainabilityActionCategory.id,
        data,
      });
      methods.reset(response);
      toast.success(
        <Trans>Sustainability action category updated successfully!</Trans>,
      );
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>Edit sustainability action category</Trans>}
      backLink='/sustainability_actions'
    >
      <FormProvider {...methods}>
        <SustainabilityActionCategoryForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
