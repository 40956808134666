/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  components,
  type ClearIndicatorProps,
  type DropdownIndicatorProps,
  type MultiValueRemoveProps,
  type GroupBase,
} from 'react-select';
import CreatableSelect, { type CreatableProps } from 'react-select/creatable';
import {
  Controller,
  type FieldValues,
  type Control,
  type Path,
} from 'react-hook-form';
import { tv } from 'tailwind-variants';
import { ChevronDown, X } from 'lucide-react';

import { Label } from '../label';

function DropdownIndicator(
  properties: DropdownIndicatorProps<{
    readonly value: string;
    readonly label: string;
  }>,
) {
  return (
    <components.DropdownIndicator {...properties}>
      <ChevronDown
        aria-hidden
        className='w-4 h-4 text-gray-600 dark:text-zinc-400 forced-colors:text-[ButtonText] group-disabled:text-gray-200 dark:group-disabled:text-zinc-600 forced-colors:group-disabled:text-[GrayText]'
      />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(
  properties: ClearIndicatorProps<{
    readonly value: string;
    readonly label: string;
  }>,
) {
  return (
    <components.ClearIndicator {...properties}>
      <X
        aria-hidden
        className='w-3.5 h-3.5  text-gray-600 dark:text-zinc-400 forced-colors:text-[ButtonText] group-disabled:text-gray-200 dark:group-disabled:text-zinc-600 forced-colors:group-disabled:text-[GrayText]'
      />
    </components.ClearIndicator>
  );
}

function MultiValueRemove(properties: MultiValueRemoveProps) {
  return (
    <components.MultiValueRemove {...properties}>
      <X
        aria-hidden
        className='w-3.5 h-3.5 ml-2 text-tremor-content-subtle font-semibold forced-colors:text-[ButtonText] group-disabled:text-gray-200 dark:group-disabled:text-zinc-600 forced-colors:group-disabled:text-[GrayText]'
      />
    </components.MultiValueRemove>
  );
}

const commonStyles = {
  input: (base: any) => ({
    ...base,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    whiteSpace: 'normal',
    overflow: 'visible',
  }),
  control: (base: any) => ({
    ...base,
    transition: 'none',
  }),
};

const commonClassNames = {
  control: tv({
    base: 'text-sm w-full outline-none text-left whitespace-nowrap truncate rounded-tremor-default focus:ring-2 transition duration-100 border shadow-tremor-input focus:border-tremor-brand-subtle focus:ring-tremor-brand-muted dark:shadow-dark-tremor-input dark:focus:border-dark-tremor-brand-subtle dark:focus:ring-dark-tremor-brand-muted bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border',
  }),
  placeholder: () => 'text-gray-500 pl-1 py-0.5',
  input: () => 'pl-1 py-0.5',
  valueContainer: () => 'pr-8 py-1.5 pl-1.5 gap-x-1',
  singleValue: () => 'ml-1',
  multiValue: () =>
    'max-w-[100px] lg:max-w-[200px] flex justify-center items-center pl-2 pr-1.5 py-1 font-medium rounded-tremor-small bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis',
  multiValueLabel: () => 'py-0.5 text-xs',
  multiValueRemove: () => 'hover:bg-red-50 hover:text-red-800 rounded',
  indicatorsContainer: () => 'p-1 gap-1',
  clearIndicator: () => 'p-1 hover:bg-red-50 hover:text-red-800 rounded',
  indicatorSeparator: () => 'bg-gray-300',
  dropdownIndicator: () =>
    'p-1 hover:bg-gray-100 text-gray-500 rounded hover:text-black',
  menu: tv({
    base: 'divide-y overflow-y-auto outline-none rounded-tremor-default max-h-[228px] left-0 border my-1 bg-tremor-background border-tremor-border divide-tremor-border shadow-tremor-dropdown dark:bg-dark-tremor-background dark:border-dark-tremor-border dark:divide-dark-tremor-border dark:shadow-dark-tremor-dropdown',
    variants: {
      isEntering: {
        true: 'animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 ease-out duration-200',
      },
      isExiting: {
        true: 'animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 ease-in duration-150',
      },
    },
  }),
  groupHeading: () => 'ml-3 mt-2 mb-1 text-gray-500 text-sm',
  option: tv({
    base: 'text-xs tremor-MultiSelectItem-root flex justify-start items-center cursor-default text-tremor-default p-2.5 ui-selected:text-tremor-content-strong text-tremor-content-emphasis dark:ui-active:bg-dark-tremor-background-muted dark:ui-active:text-dark-tremor-content-strong dark:ui-selected:text-dark-tremor-content-strong dark:ui-selected:bg-dark-tremor-background-muted dark:text-dark-tremor-content-emphasis',
    variants: {
      isFocused: {
        true: 'bg-tremor-background-muted',
      },
    },
  }),
  noOptionsMessage: () =>
    'text-gray-500 p-2 bg-gray-50 border border-gray-200 rounded',
};

export type MultipleCreatableSelectProperties<T extends FieldValues> =
  CreatableProps<
    {
      readonly value: string;
      readonly label: string;
    },
    true,
    GroupBase<{
      readonly value: string;
      readonly label: string;
    }>
  > & {
    readonly control: Control<T>;
    readonly name: Path<T>;
    readonly options: ReadonlyArray<{
      readonly value: string;
      readonly label: string;
    }>;
    readonly label?: string;
    readonly errorMessage?: string;
  };

export function MultipleCreatableSelect<T extends FieldValues>({
  control,
  name,
  options,
  label,
  errorMessage,
  ...rest
}: MultipleCreatableSelectProperties<T>) {
  return (
    <div className='w-full'>
      {label && (
        <div className='mb-2 block'>
          <Label htmlFor={name} value={label} />
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <CreatableSelect
              isMulti
              {...field}
              unstyled
              options={options}
              styles={{ ...commonStyles }}
              components={{
                DropdownIndicator,
                ClearIndicator,
                MultiValueRemove,
              }}
              classNames={{ ...commonClassNames }}
              {...rest}
            />
            {Boolean(errorMessage) && (
              <p className='text-sm text-red-500 mt-1'>{errorMessage}</p>
            )}
          </>
        )}
      />
    </div>
  );
}
