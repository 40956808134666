import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

export const locales = {
  en: 'English',
  nl: 'Nederlands',
};
export const defaultLocale = 'nl';

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`../locales/${locale}.po`); // eslint-disable-line @typescript-eslint/no-unsafe-assignment

  i18n.load(locale, messages); // eslint-disable-line @typescript-eslint/no-unsafe-argument
  i18n.activate(locale);
}
