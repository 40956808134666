import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import {
  Button,
  DateRangePicker,
  type DateRangePickerValue,
} from '@tremor/react';
import { Trans } from '@lingui/macro';

import {
  Wrapper,
  DataTable,
  registrationColumns,
  QuickSelect,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useRegistrations, useExportRegistrations } from '@/api';
import { RegistrationType } from '@/api/model';

export const Route = createFileRoute('/_authenticated/registrations/')({
  component: Registrations,
});

function Registrations() {
  const [selectedDates, setSelectedDates] = useState<DateRangePickerValue>({
    from: new Date(new Date().getFullYear(), 0, 1, 1),
    to: new Date(new Date().getFullYear() + 1, 0, 1, 1),
    selectValue: 'y',
  });
  const [registrationType, setRegistrationType] = useState<RegistrationType>();
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: [
      'name',
      'email',
      'phoneNumber',
      'sustainabilityActionsTitle',
    ],
    extraQueryParameters: {
      registrationTypeEq: registrationType,
    },
  });
  const { data } = useRegistrations(
    {
      ...queryParameters,
      q: {
        ...queryParameters.q,
        created_at_gteq: selectedDates.from?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
        created_at_lteq: selectedDates.to?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
      },
    },
    queryOptions,
  );
  const { registrations = [], meta } = data ?? {};

  const { mutateAsync: exportRegistrations } = useExportRegistrations({
    mutation: {
      async onSuccess(response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `registrations-${selectedDates.from?.getFullYear()}.csv`;
        link.setAttribute('download', fileName);
        document.body.append(link);
        link.click();
        link.remove();
      },
    },
  });

  return (
    <Wrapper
      header={<Trans>Registrations</Trans>}
      actions={
        <Button
          variant='secondary'
          onClick={async () =>
            exportRegistrations({
              params: {
                ...queryParameters,
                q: {
                  ...queryParameters.q,
                  created_at_gteq: selectedDates.from?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
                  created_at_lteq: selectedDates.to?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
                },
              },
            })
          }
        >
          <Trans>Export CSV</Trans>
        </Button>
      }
      extraActions={
        <div className='flex flex-col gap-2 justify-end'>
          <QuickSelect
            selected={registrationType}
            setSelected={setRegistrationType}
            options={[
              { key: undefined, value: <Trans>All</Trans> },
              { key: RegistrationType.advise, value: <Trans>Advise</Trans> },
              {
                key: RegistrationType.subsidy_alert,
                value: <Trans>Subsidy alert</Trans>,
              },
              {
                key: RegistrationType.collective_purchase_alert,
                value: <Trans>Collective purchase alert</Trans>,
              },
              {
                key: RegistrationType.collective_purchase_register,
                value: <Trans>Collective purchase register</Trans>,
              },
              {
                key: RegistrationType.identifier_request,
                value: <Trans>Identifier request</Trans>,
              },
            ]}
          />
          <div className='flex justify-end z-20'>
            <DateRangePicker
              enableYearNavigation
              weekStartsOn={1}
              value={selectedDates}
              onValueChange={setSelectedDates}
            />
          </div>
        </div>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={registrations}
        columns={registrationColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Wrapper>
  );
}
