import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, GrantForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useGrantSuspense,
  useUpdateGrant,
  getGrantQueryKey,
  getGrantsQueryKey,
  getGrantSuspenseQueryOptions,
  useSustainabilityActions,
} from '@/api';
import type { GrantCrud } from '@/api/model';
import { grantSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/grants/$grantId/edit')({
  loader: async ({ context: { queryClient }, params: { grantId } }) =>
    queryClient.ensureQueryData(getGrantSuspenseQueryOptions(Number(grantId))),
  component: EditGrant,
});

function EditGrant() {
  const grantId = Route.useParams({
    select: ({ grantId }) => Number(grantId),
  });
  const { data: grant } = useGrantSuspense(grantId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateGrant({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getGrantsQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getGrantQueryKey(grantId),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};

  const methods = useForm<GrantCrud>({
    resolver: zodResolver(grantSchema),
    defaultValues: {
      ...grant,
      amount: grant.amount ? grant.amount.cents / 100 : null,
      sustainabilityActionIds: grant?.sustainabilityActions?.map(
        (sustainabilityAction) => sustainabilityAction.id.toString(),
      ),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: grant.id,
        data,
      });
      methods.reset({
        ...response,
        amount: response.amount ? response.amount.cents / 100 : null,
        sustainabilityActionIds: response?.sustainabilityActions?.map(
          (sustainabilityAction) => sustainabilityAction.id.toString(),
        ),
      });
      toast.success(<Trans>Grant updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>Edit grant</Trans>} backLink='/grants'>
      <FormProvider {...methods}>
        <GrantForm
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
