/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum AudienceBlockIcon {
  arrow_right = 'arrow_right',
  arrow_right_white = 'arrow_right_white',
  bird = 'bird',
  camera = 'camera',
  check = 'check',
  chevron = 'chevron',
  chevron_black = 'chevron_black',
  close = 'close',
  code = 'code',
  construction = 'construction',
  construction_white = 'construction_white',
  download = 'download',
  edit = 'edit',
  energy_label = 'energy_label',
  external_arrow = 'external_arrow',
  external_arrow_blue = 'external_arrow_blue',
  external_arrow_white = 'external_arrow_white',
  eye = 'eye',
  floor = 'floor',
  gas = 'gas',
  group = 'group',
  home = 'home',
  inkoopactie = 'inkoopactie',
  installateur = 'installateur',
  leaf = 'leaf',
  mail = 'mail',
  mail_white = 'mail_white',
  maatregelen = 'maatregelen',
  minus = 'minus',
  mobile = 'mobile',
  mobile_blue = 'mobile_blue',
  money = 'money',
  nope = 'nope',
  pdf = 'pdf',
  play = 'play',
  plus = 'plus',
  power = 'power',
  pump = 'pump',
  question = 'question',
  roof = 'roof',
  roof_white = 'roof_white',
  save = 'save',
  search = 'search',
  send = 'send',
  solar = 'solar',
  whatsapp = 'whatsapp',
  whatsapp_blue = 'whatsapp_blue',
}
