import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  TextInput,
  Textarea,
  PriceInput,
  MultipleCreatableSelect,
  MultiSelect,
} from '@/components';
import type { Tag, SustainabilityAction } from '@/api/model';
import type { collectivePurchaseSchema } from '@/schemas';

export function CollectivePurchaseForm({
  tags,
  sustainabilityActions,
  onSave,
}: {
  readonly tags: readonly Tag[];
  readonly sustainabilityActions: readonly SustainabilityAction[];
  readonly onSave: () => void;
}) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof collectivePurchaseSchema>>();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='title'
        label={_(msg`Title`)}
        placeholder={_(msg`Title`)}
        control={control}
        errorMessage={errors.title?.message}
      />
      <Textarea
        name='description'
        label={_(msg`Description`)}
        placeholder={_(msg`Description`)}
        control={control}
        errorMessage={errors.description?.message}
      />
      <TextInput
        name='deadline'
        label={_(msg`Deadline`)}
        placeholder={_(msg`Deadline`)}
        control={control}
        errorMessage={errors.deadline?.message}
      />
      <PriceInput
        name='amount'
        label={_(msg`Amount`)}
        placeholder={_(msg`Amount`)}
        control={control}
        errorMessage={errors.amount?.message}
      />
      <TextInput
        name='url'
        label={_(msg`Link`)}
        placeholder={_(msg`Link`)}
        control={control}
        errorMessage={errors.url?.message}
        type='url'
      />
      <MultipleCreatableSelect
        name='tagList'
        label={_(msg`Tags`)}
        placeholder={_(msg`Tags`)}
        options={tags.map((tag) => ({
          value: tag.name,
          label: tag.name,
        }))}
        control={control}
        errorMessage={errors.tagList?.message}
      />
      <MultiSelect
        name='sustainabilityActionIds'
        label={_(msg`Sustainability actions`)}
        options={sustainabilityActions.map((sustainabilityAction) => ({
          value: sustainabilityAction.id.toString(),
          label: sustainabilityAction.title,
        }))}
        control={control}
        errorMessage={errors.sustainabilityActionIds?.message}
      />
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
