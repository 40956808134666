import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, DataTable, pageColumns, DeleteModal } from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getPagesQueryKey, useDeletePage, usePages } from '@/api';
import type { Page } from '@/api/model';

export const Route = createFileRoute('/_authenticated/pages/')({
  component: Pages,
});

function Pages() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['title'],
  });
  const { data } = usePages(queryParameters, queryOptions);
  const { pages = [], meta } = data ?? {};

  const { mutateAsync: deletePage } = useDeletePage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getPagesQueryKey(),
        });
      },
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState<Page>();

  const onDelete = async () => {
    if (!selectedPage) return;

    await deletePage({ id: selectedPage.id });
    setOpenDeleteModal(false);
    toast.success(<Trans>Page deleted successfully!</Trans>);
  };

  return (
    <Wrapper
      header={<Trans>Pages</Trans>}
      actions={
        <Link to='/pages/new'>
          <Button variant='secondary'>
            <Trans>Add new</Trans>
          </Button>
        </Link>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={pages}
        columns={pageColumns({
          onDelete(page) {
            setSelectedPage(page);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <DeleteModal
        type={<Trans>Page</Trans>}
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
