/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/naming-convention */
import { mergeAttributes } from '@tiptap/react';
import Link from '@tiptap/extension-link';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    linkBlock: {
      setLinkBlock: (attributes: {
        href: string;
        target?: string | undefined;
        rel?: string | undefined;
        class?: string | undefined;
      }) => ReturnType;
    };
  }
}

export const LinkBlock = Link.extend({
  name: 'linkBlock',

  addCommands() {
    return {
      setLinkBlock:
        (attributes) =>
        ({ chain }) => {
          console.log(this.name);
          return chain()
            .setMark(this.name, { ...attributes, 'data-type': 'article' })
            .setMeta('preventAutolink', true)
            .run();
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a.article',
        priority: 100,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'article',
      }),
      0,
    ];
  },
});
