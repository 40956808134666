import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TextInput, Textarea, Select } from '@/components';
import { formatEnum } from '@/utils';
import { AudienceBlockIcon, AudienceIllustration } from '@/api/model';
import type { audienceSchema } from '@/schemas';

export function AudienceForm({ onSave }: { readonly onSave: () => void }) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof audienceSchema>>();

  const renderAudienceBlock = (index: number) => {
    return (
      <div className='flex flex-col gap-4'>
        <TextInput
          name={`audienceBlocks.${index}.audienceBlockId`}
          control={control}
          className='hidden'
        />
        <TextInput
          name={`audienceBlocks.${index}.title`}
          label={_(msg`Title`)}
          placeholder={_(msg`Title`)}
          control={control}
          errorMessage={errors.audienceBlocks?.[index]?.title?.message}
        />
        <Textarea
          name={`audienceBlocks.${index}.subtitle`}
          label={_(msg`Subtitle`)}
          placeholder={_(msg`Subtitle`)}
          control={control}
          errorMessage={errors.audienceBlocks?.[index]?.subtitle?.message}
        />
        <Select
          name={`audienceBlocks.${index}.icon`}
          label={_(msg`Icon`)}
          placeholder={_(msg`Icon`)}
          control={control}
          options={Object.keys(AudienceBlockIcon).map((icon) => ({
            value: icon,
            label: formatEnum(icon),
          }))}
          errorMessage={errors.audienceBlocks?.[index]?.icon?.message}
        />
        <TextInput
          name={`audienceBlocks.${index}.url`}
          label={_(msg`Link`)}
          placeholder={_(msg`Link`)}
          control={control}
          errorMessage={errors.audienceBlocks?.[index]?.url?.message}
          type='url'
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='name'
        label={_(msg`Name`)}
        placeholder={_(msg`Name`)}
        control={control}
        errorMessage={errors.name?.message}
      />
      <TextInput
        name='title'
        label={_(msg`Title`)}
        placeholder={_(msg`Title`)}
        control={control}
        errorMessage={errors.title?.message}
      />
      <Textarea
        name='subtitle'
        label={_(msg`Subtitle`)}
        placeholder={_(msg`Subtitle`)}
        control={control}
        errorMessage={errors.subtitle?.message}
      />
      <Select
        name='illustration'
        label={_(msg`Illustration`)}
        placeholder={_(msg`Illustration`)}
        control={control}
        options={Object.keys(AudienceIllustration).map((illustration) => ({
          value: illustration,
          label: formatEnum(illustration),
        }))}
        errorMessage={errors.illustration?.message}
      />

      <div className='grid grid-cols-3 gap-4'>
        {renderAudienceBlock(0)}
        {renderAudienceBlock(1)}
        {renderAudienceBlock(2)}
      </div>

      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
