/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { useProfile, useSessions, useSignIn, useSignOut } from '@/api';
import type { Profile, Session, Errors } from '@/api/model';
import { isServerError } from '@/utils';

export type AuthContextType = {
  readonly error:
    | {
        readonly errors: Errors | undefined;
        readonly status: number | undefined;
      }
    | undefined;
  profile: Profile | undefined;
  sessions: Session[] | undefined;
  authenticated: boolean;
  token: string | null;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
};

const key = 'duurzaam.auth.token';

function getStoredToken() {
  return localStorage.getItem(key);
}

function setStoredToken(token: string | null) {
  if (token) {
    localStorage.setItem(key, token);
  } else {
    localStorage.removeItem(key);
  }
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [token, setToken] = React.useState<string | null>(getStoredToken());
  const authenticated = Boolean(token);
  const [error, setError] = useState<{
    errors: Errors | undefined;
    status: number | undefined;
  }>();
  const { data: profile } = useProfile({
    query: { enabled: authenticated },
  });
  const { data } = useSessions({
    query: { enabled: authenticated },
  });
  const { sessions = [] } = data ?? {};
  const { mutateAsync: createSession } = useSignIn({
    mutation: {
      async onSuccess() {
        setToken(getStoredToken());
      },
      async onError(error) {
        if (isServerError(error)) {
          setError({
            errors: (error.response?.data as any)?.errors as unknown as Errors,
            status: error.response?.status,
          });
        }

        localStorage.removeItem('token');
      },
    },
  });
  const { mutateAsync: deleteSession } = useSignOut({
    mutation: {
      async onSuccess() {
        setStoredToken(null);
        setToken(null);
      },
      async onError(error) {
        if (isServerError(error)) {
          setError({
            errors: (error.response?.data as any)?.errors as unknown as Errors,
            status: error.response?.status,
          });
        }
      },
    },
  });

  const signIn = useCallback(
    async (email: string, password: string) => {
      await createSession({ data: { email, password } });
    },
    [createSession],
  );

  const signOut = useCallback(async () => {
    await deleteSession();
  }, [deleteSession]);

  const value = useMemo(() => {
    return { authenticated, error, profile, sessions, token, signIn, signOut };
  }, [authenticated, error, profile, sessions, token, signIn, signOut]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
