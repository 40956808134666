import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import {
  Wrapper,
  DataTable,
  grantColumns,
  DeleteModal,
  QuickSelect,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getGrantsQueryKey, useDeleteGrant, useGrants } from '@/api';
import { type Grant, GrantType } from '@/api/model';

export const Route = createFileRoute('/_authenticated/grants/')({
  component: Grants,
});

function Grants() {
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [grantType, setGrantType] = useState<GrantType>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['title'],
    extraQueryParameters: {
      grantTypeEq: grantType,
    },
  });
  const { data } = useGrants(queryParameters, queryOptions);
  const { grants = [], meta } = data ?? {};

  const { mutateAsync: deleteGrant } = useDeleteGrant({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getGrantsQueryKey(),
        });
      },
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState<Grant>();

  const onDelete = async () => {
    if (!selectedGrant) return;

    await deleteGrant({ id: selectedGrant.id });
    setOpenDeleteModal(false);
    toast.success(<Trans>Grant deleted successfully!</Trans>);
  };

  return (
    <Wrapper
      header={<Trans>Grants</Trans>}
      actions={
        <Link to='/grants/new'>
          <Button variant='secondary'>
            <Trans>Add new</Trans>
          </Button>
        </Link>
      }
      extraActions={
        <QuickSelect
          selected={grantType}
          setSelected={setGrantType}
          options={[
            { key: undefined, value: <Trans>All</Trans> },
            { key: GrantType.subsidy, value: <Trans>Subsidy</Trans> },
            { key: GrantType.loan, value: <Trans>Loan</Trans> },
          ]}
        />
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={grants}
        columns={grantColumns({
          onDelete(grant) {
            setSelectedGrant(grant);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <DeleteModal
        type={<Trans>Grant</Trans>}
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
