import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { t, msg } from '@lingui/macro';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { DataTableColumnHeader } from '../data-table';

import { type Registration, RegistrationType } from '@/api/model';
import { formatDate } from '@/utils';

const registrationTypes = {
  [RegistrationType.advise]: msg`Advise`,
  [RegistrationType.subsidy_alert]: msg`Subsidy alert`,
  [RegistrationType.collective_purchase_alert]: msg`Collective purchase alert`,
  [RegistrationType.collective_purchase_register]: msg`Collective purchase register`,
  [RegistrationType.identifier_request]: msg`Identifier request`,
};

const columnHelper = createColumnHelper<Registration>();
export const registrationColumns = () => [
  columnHelper.accessor((registration) => registration.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Name`} />
    ),
    cell: (properties) => (
      <Link
        to='/registrations/$registrationId'
        params={{
          registrationId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((registration) => registration.email, {
    id: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Email`} />
    ),
  }),
  columnHelper.accessor((registration) => registration.phoneNumber, {
    id: 'phoneNumber',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t`Phone number`} />
    ),
  }),
  columnHelper.accessor(
    (registration) => registration.registrationType as string,
    {
      id: 'registrationType',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t`Registration type`} />
      ),
      cell: (properties) => (
        <div className='flex'>
          {Boolean(properties.getValue()) &&
            i18n._(
              registrationTypes[properties.getValue() as RegistrationType],
            )}
        </div>
      ),
    },
  ),
  columnHelper.accessor(
    (registration) =>
      registration.createdAt && formatDate(registration.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Date' />
      ),
    },
  ),
];
