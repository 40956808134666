/* eslint-disable max-depth */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const customFormData = <Body extends Record<string, any>>(
  body: Body,
): FormData => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(body)) {
    if (Array.isArray(value)) {
      for (const entry of value) {
        if (
          typeof entry === 'object' &&
          !Array.isArray(entry) &&
          entry !== null
        ) {
          for (const [k, v] of Object.entries(entry)) {
            if (v !== undefined) {
              formData.append(`${key}[][${k}]`, v as any);
            }
          }
        } else {
          formData.append(`${key}[]`, entry);
        }
      }
    } else if (value !== undefined) {
      formData.append(key, value);
    }
  }

  return formData;
};

export default customFormData;
