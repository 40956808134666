/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import qs from 'qs';

const key = 'duurzaam.auth.token';

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];

    if (isIsoDateString(value)) {
      body[key] = new Date(value); // Default JS conversion
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }
}

const api = axios.create();

api.interceptors.response.use(
  (response) => {
    const token = response.headers['x-session-token'];
    if (token) {
      localStorage.setItem(key, token as string);
    }

    handleDates(response.data);
    return response;
  },
  async (error: ErrorType<unknown>) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      localStorage.removeItem(key);
    }

    throw error;
  },
);

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(key);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  async (error: ErrorType<unknown>) => {
    throw error;
  },
);

export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  return api<T>({
    ...config,
    ...options,
    paramsSerializer(parameters) {
      return qs.stringify(parameters, { arrayFormat: 'brackets' });
    },
  }).then(({ data }) => data); // eslint-disable-line promise/prefer-await-to-then
};

export { api };
