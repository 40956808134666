import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, ContentBlockForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useContentBlockSuspense,
  useUpdateContentBlock,
  getContentBlockQueryKey,
  getContentBlocksQueryKey,
  getContentBlockSuspenseQueryOptions,
} from '@/api';
import { contentBlockSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/content_blocks/$contentBlockId/edit',
)({
  loader: async ({ context: { queryClient }, params: { contentBlockId } }) =>
    queryClient.ensureQueryData(
      getContentBlockSuspenseQueryOptions(Number(contentBlockId)),
    ),
  component: EditContentBlock,
});

function EditContentBlock() {
  const contentBlockId = Route.useParams({
    select: ({ contentBlockId }) => Number(contentBlockId),
  });
  const { data: contentBlock } = useContentBlockSuspense(contentBlockId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateContentBlock({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getContentBlocksQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getContentBlockQueryKey(contentBlockId),
        });
      },
    },
  });

  const methods = useForm<zod.infer<typeof contentBlockSchema>>({
    resolver: zodResolver(contentBlockSchema),
    defaultValues: contentBlock,
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: contentBlock.id,
        data,
      });
      methods.reset(response);
      toast.success(<Trans>Content block updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>Edit content block</Trans>}
      backLink='/content_blocks'
    >
      <FormProvider {...methods}>
        <ContentBlockForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
