import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TextInput } from '@/components';
import type { settingSchema } from '@/schemas';

export function SettingForm({ onSave }: { readonly onSave: () => void }) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof settingSchema>>();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='phoneNumber'
        label={_(msg`Phone number`)}
        placeholder={_(msg`Phone number`)}
        control={control}
        errorMessage={errors.phoneNumber?.message}
      />
      <TextInput
        name='whatsapp'
        label={_(msg`WhatsApp`)}
        placeholder={_(msg`WhatsApp`)}
        control={control}
        errorMessage={errors.whatsapp?.message}
      />
      <TextInput
        name='email'
        label={_(msg`Email`)}
        placeholder={_(msg`Email`)}
        control={control}
        errorMessage={errors.email?.message}
      />
      <TextInput
        name='twitter'
        label={_(msg`X`)}
        placeholder={_(msg`X`)}
        control={control}
        errorMessage={errors.twitter?.message}
      />
      <TextInput
        name='facebook'
        label={_(msg`Facebook`)}
        placeholder={_(msg`Facebook`)}
        control={control}
        errorMessage={errors.facebook?.message}
      />
      <TextInput
        name='instagram'
        label={_(msg`Instagram`)}
        placeholder={_(msg`Instagram`)}
        control={control}
        errorMessage={errors.instagram?.message}
      />
      <TextInput
        name='linkedin'
        label={_(msg`LinkedIn`)}
        placeholder={_(msg`LinkedIn`)}
        control={control}
        errorMessage={errors.linkedin?.message}
      />
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
