import type { PropsWithChildren, ReactNode } from 'react';

import { Header } from '../atoms';

export function Wrapper({
  children,
  header,
  backLink,
  actions,
  extraActions,
  onBack,
  onSearch,
}: PropsWithChildren<{
  readonly header?: ReactNode;
  readonly backLink?: string;
  readonly actions?: ReactNode;
  readonly extraActions?: ReactNode;
  readonly onBack?: () => void;
  readonly onSearch?: (value: string) => void;
}>) {
  return (
    <div className='w-full max-w-[1200px] mx-auto'>
      {header && (
        <Header
          backLink={backLink}
          actions={actions}
          extraActions={extraActions}
          onBack={onBack}
          onSearch={onSearch}
        >
          {header}
        </Header>
      )}
      {children}
    </div>
  );
}
