import { createFileRoute } from '@tanstack/react-router';
import { Trans } from '@lingui/macro';

import { Wrapper, DataTable, contentBlockColumns } from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useContentBlocks } from '@/api';

export const Route = createFileRoute('/_authenticated/content_blocks/')({
  component: ContentBlocks,
});

function ContentBlocks() {
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['title'],
  });
  const { data } = useContentBlocks(queryParameters, queryOptions);
  const { contentBlocks = [], meta } = data ?? {};

  return (
    <Wrapper header={<Trans>Content blocks</Trans>} onSearch={setSearchQuery}>
      <DataTable
        data={contentBlocks}
        columns={contentBlockColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Wrapper>
  );
}
