import { useFormContext, useFieldArray } from 'react-hook-form';
import { Button, List, ListItem } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { filesize } from 'filesize';
import { FileText, Trash, ArchiveRestore } from 'lucide-react';

import { TextInput, Textarea, FileInput, Label, Editor } from '@/components';
import type { pageSchema } from '@/schemas';

export function PageForm() {
  const { _ } = useLingui();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<zod.infer<typeof pageSchema>>();
  const { fields, append, update } = useFieldArray({
    name: 'documents',
    control,
  });

  const renderDocuments = () => {
    return (
      <List className='mt-4'>
        {fields.map((field, index) => {
          return (
            <ListItem
              key={field.id}
              className={field._destroy ? 'bg-red/20 px-2' : 'px-2'}
            >
              <div className='flex gap-2'>
                <div className='flex items-center'>
                  <FileText />
                </div>
                <div>
                  <p className='text-sm'>{field.name}</p>
                  {field.fileSize ? (
                    <p className='text-xs'>{filesize(field.fileSize)}</p>
                  ) : null}
                </div>
              </div>
              {field._destroy ? (
                <Button
                  variant='light'
                  onClick={() => {
                    update(index, {
                      ...field,
                      _destroy: false,
                    });
                  }}
                >
                  <ArchiveRestore size='18' />
                </Button>
              ) : (
                <Button
                  variant='light'
                  onClick={() => {
                    update(index, {
                      ...field,
                      _destroy: true,
                    });
                  }}
                >
                  <Trash size='18' />
                </Button>
              )}
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <>
      <div className='grid grid-cols-2 gap-4 mb-4'>
        <div className='flex flex-col gap-4'>
          <TextInput
            name='title'
            label={_(msg`Title`)}
            placeholder={_(msg`Title`)}
            control={control}
            errorMessage={errors.title?.message}
          />
          <Textarea
            name='subtitle'
            label={_(msg`Subtitle`)}
            placeholder={_(msg`Subtitle`)}
            control={control}
            errorMessage={errors.subtitle?.message}
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label value={_(msg`Image`)} />
          </div>
          <FileInput
            name='image'
            label={_(msg`Image`)}
            placeholder={_(msg`Image`)}
            control={control}
            variant='image'
            maxImageSize='1200x1200'
            errorMessage={errors.image?.message}
            onRemove={() => {
              setValue('image', null);
              setValue('removeImage', true);
            }}
          />
        </div>
      </div>
      <div className='mb-2 block'>
        <Label value={_(msg`Content`)} />
      </div>
      <Editor
        content={getValues('content')}
        onChange={(content) => {
          setValue('content', content);
        }}
      />
      <div className='grid grid-cols-2 gap-4 my-4 pb-4'>
        <div>
          <Label value={_(msg`Files`)} />
          <FileInput
            name='file'
            label={_(msg`File`)}
            placeholder={_(msg`File`)}
            control={control}
            errorMessage={errors.documents?.[0]?.file?.message}
            onChange={(event) => {
              if (event.target.files) {
                for (const file of event.target.files) {
                  append({
                    name: file.name,
                    file,
                    fileSize: file.size,
                    fileType: file.type,
                  });
                }
              }
            }}
          />
        </div>
        <div>{renderDocuments()}</div>
      </div>
    </>
  );
}
