/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum ContentType {
  grant_help = 'grant_help',
  grant_stay_informed = 'grant_stay_informed',
  collective_purchase_help = 'collective_purchase_help',
  collective_purchase_stay_informed = 'collective_purchase_stay_informed',
  installer_help = 'installer_help',
  installer_stay_informed = 'installer_stay_informed',
  premise_identifier = 'premise_identifier',
  advice = 'advice',
  contact = 'contact',
  social_media = 'social_media',
}
