/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum SustainabilityActionIcon {
  dak = 'dak',
  floor = 'floor',
  roof = 'roof',
  verwarm = 'verwarm',
  zonnepanelen = 'zonnepanelen',
  muur = 'muur',
  text_bubble = 'text_bubble',
  warmte_electric = 'warmte_electric',
  raam = 'raam',
  ventilator = 'ventilator',
  warmte = 'warmte',
}
