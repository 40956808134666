import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, CollectivePurchaseForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCollectivePurchaseSuspense,
  useUpdateCollectivePurchase,
  getCollectivePurchaseQueryKey,
  getCollectivePurchasesQueryKey,
  getCollectivePurchaseSuspenseQueryOptions,
  useSustainabilityActions,
  useCollectivePurchaseTags,
} from '@/api';
import { collectivePurchaseSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/collective_purchases/$collectivePurchaseId/edit',
)({
  loader: async ({
    context: { queryClient },
    params: { collectivePurchaseId },
  }) =>
    queryClient.ensureQueryData(
      getCollectivePurchaseSuspenseQueryOptions(Number(collectivePurchaseId)),
    ),
  component: EditCollectivePurchase,
});

function EditCollectivePurchase() {
  const collectivePurchaseId = Route.useParams({
    select: ({ collectivePurchaseId }) => Number(collectivePurchaseId),
  });
  const { data: collectivePurchase } =
    useCollectivePurchaseSuspense(collectivePurchaseId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateCollectivePurchase({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getCollectivePurchasesQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getCollectivePurchaseQueryKey(collectivePurchaseId),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};
  const { data: tagData } = useCollectivePurchaseTags();
  const { tags = [] } = tagData ?? {};

  const methods = useForm<zod.infer<typeof collectivePurchaseSchema>>({
    resolver: zodResolver(collectivePurchaseSchema),
    defaultValues: {
      ...collectivePurchase,
      amount: collectivePurchase.amount
        ? collectivePurchase.amount.cents / 100
        : null,
      tagList: collectivePurchase.tagList.map((tag) => ({
        value: tag,
        label: tag,
      })),
      sustainabilityActionIds: collectivePurchase?.sustainabilityActions?.map(
        (sustainabilityAction) => sustainabilityAction.id.toString(),
      ),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: collectivePurchase.id,
        data: {
          ...data,
          amount: data.amount ?? null,
          tagList: data.tagList?.map((tag) => tag.value),
        },
      });
      methods.reset({
        ...response,
        amount: response.amount ? response.amount.cents / 100 : null,
        tagList: response.tagList.map((tag) => ({
          value: tag,
          label: tag,
        })),
        sustainabilityActionIds: response?.sustainabilityActions?.map(
          (sustainabilityAction) => sustainabilityAction.id.toString(),
        ),
      });
      toast.success(<Trans>Collective purchase updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>Edit collective purchase</Trans>}
      backLink='/collective_purchases'
    >
      <FormProvider {...methods}>
        <CollectivePurchaseForm
          tags={tags}
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
