/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/naming-convention */
import { mergeAttributes, Node } from '@tiptap/react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textBlock: {
      setTextBlock: () => ReturnType;
      toggleTextBlock: () => ReturnType;
    };
  }
}

export const TextBlock = Node.create({
  name: 'textBlock',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: 'text*',

  marks: '',

  group: 'block',

  defining: true,

  parseHTML() {
    return [{ tag: 'section.text-block' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'section',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'text-block',
      }),
      0,
    ];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setTextBlock:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name, { class: 'text-block' });
        },
      toggleTextBlock:
        () =>
        ({ commands }) => {
          return commands.toggleNode(this.name, 'paragraph');
        },
    };
  },
});
