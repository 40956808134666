import { useFormContext } from 'react-hook-form';
import { Button } from '@tremor/react';
import type { z as zod } from 'zod';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TextInput, Textarea, Select } from '@/components';
import { formatEnum } from '@/utils';
import {
  type SustainabilityActionCategory,
  SustainabilityActionIcon,
} from '@/api/model';
import type { sustainabilityActionSchema } from '@/schemas';

export function SustainabilityActionForm({
  sustainabilityActionCategories,
  onSave,
}: {
  readonly sustainabilityActionCategories: readonly SustainabilityActionCategory[];
  readonly onSave: () => void;
}) {
  const { _ } = useLingui();
  const {
    control,
    formState: { errors },
  } = useFormContext<zod.infer<typeof sustainabilityActionSchema>>();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        name='title'
        label={_(msg`Title`)}
        placeholder={_(msg`Title`)}
        control={control}
        errorMessage={errors.title?.message}
      />
      <Textarea
        name='shortDescription'
        label={_(msg`Short description`)}
        placeholder={_(msg`Short description`)}
        control={control}
        errorMessage={errors.shortDescription?.message}
      />
      <Textarea
        name='longDescription'
        label={_(msg`Long description`)}
        placeholder={_(msg`Long description`)}
        control={control}
        errorMessage={errors.longDescription?.message}
      />
      <TextInput
        name='url'
        label={_(msg`Link`)}
        placeholder={_(msg`Link`)}
        control={control}
        errorMessage={errors.url?.message}
        type='url'
      />
      <Select
        name='icon'
        label={_(msg`Icon`)}
        placeholder={_(msg`Icon`)}
        control={control}
        options={Object.keys(SustainabilityActionIcon).map((status) => ({
          value: status,
          label: formatEnum(status),
        }))}
        errorMessage={errors.icon?.message}
      />
      <Select
        name='sustainabilityActionCategoryId'
        label={_(msg`Sustainability action category`)}
        placeholder={_(msg`Sustainability action category`)}
        control={control}
        options={sustainabilityActionCategories.map(
          (sustainabilityActionCategory) => ({
            value: sustainabilityActionCategory.id.toString(),
            label: sustainabilityActionCategory.title,
          }),
        )}
        errorMessage={errors.sustainabilityActionCategoryId?.message}
      />
      <div className='flex justify-end mb-4'>
        <Button onClick={onSave}>
          <Trans>Save</Trans>
        </Button>
      </div>
    </div>
  );
}
