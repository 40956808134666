/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Duurzaam Bladel backend
 * OpenAPI spec version: v1
 */

export enum PremiseSustainabilityActionInterest {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
  in_order = 'in_order',
}
