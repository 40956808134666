import { z as zod } from 'zod';

import { SustainabilityActionIcon } from '@/api/model';

export const sustainabilityActionSchema = zod.object({
  title: zod.string(),
  shortDescription: zod.string(),
  longDescription: zod.string(),
  url: zod
    .string()
    .transform((value) => {
      if (
        value &&
        !value.startsWith('http://') &&
        !value.startsWith('https://')
      ) {
        return `https://${value}`;
      }

      return value;
    })
    .pipe(zod.string().url().optional().or(zod.literal('')))
    .nullish(),
  icon: zod.nativeEnum(SustainabilityActionIcon),
  sustainabilityActionCategoryId: zod.string(),
});
