import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, GrantForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useCreateGrant,
  getGrantsQueryKey,
  useSustainabilityActions,
} from '@/api';
import type { GrantCrud } from '@/api/model';
import { grantSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/grants/new')({
  component: NewGrant,
});

function NewGrant() {
  const navigate = Route.useNavigate();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useCreateGrant({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getGrantsQueryKey(),
        });
      },
    },
  });
  const { data } = useSustainabilityActions();
  const { sustainabilityActions = [] } = data ?? {};

  const methods = useForm<GrantCrud>({
    resolver: zodResolver(grantSchema),
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({ data });
      methods.reset();
      toast.success(<Trans>Grant created successfully!</Trans>);
      await navigate({ to: '/grants' });
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>New grant</Trans>} backLink='/grants'>
      <FormProvider {...methods}>
        <GrantForm
          sustainabilityActions={sustainabilityActions}
          onSave={onSave}
        />
      </FormProvider>
    </Wrapper>
  );
}
