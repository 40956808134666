import type { CustomFlowbiteTheme } from 'flowbite-react';

export const flowbiteTheme: CustomFlowbiteTheme = {
  sidebar: {
    root: {
      base: 'fixed top-0 left-0 h-screen shadow-lg',
      collapsed: {
        on: 'w-16',
        off: 'w-80',
      },
      inner:
        'h-full overflow-y-auto overflow-x-hidden rounded bg-gray-100 py-8 px-6 dark:bg-gray-800 shadow-md',
    },
    item: {
      base: 'flex items-center justify-center rounded-lg p-4 text-base font-normal text-gray-900 hover:bg-primary-950 hover:text-white dark:text-white dark:hover:bg-primary-300',
      active: 'text-white bg-primary',
      icon: {
        base: 'h-6 w-6 flex-shrink-0',
        active: 'text-white dark:text-gray-100',
      },
    },
    logo: {
      base: 'mb-5 flex items-center pl-2.5',
      collapsed: {
        on: 'hidden',
        off: 'self-center whitespace-nowrap text-xl font-semibold dark:text-white',
      },
      img: '',
    },
  },
  card: {
    root: {
      children: 'flex h-full flex-col justify-center',
    },
  },
  button: {
    color: {
      primary:
        'text-white bg-primary border border-transparent enabled:hover:bg-primary-950 focus:ring-4 focus:ring-primary-300 dark:bg-primary dark:enabled:hover:bg-primary-300 dark:focus:ring-primary dark:border-primary-300',
    },
  },
  avatar: {
    root: {
      size: {
        xs: 'h-6 w-auto',
        sm: 'h-8 w-auto',
        md: 'h-10 w-auto',
        lg: 'h-20 w-auto',
        xl: 'h-36 w-auto',
      },
    },
  },
};
