import { z as zod } from 'zod';

import { AudienceIllustration, AudienceBlockIcon } from '@/api/model';

export const audienceSchema = zod.object({
  name: zod.string(),
  title: zod.string(),
  subtitle: zod.string(),
  illustration: zod.nativeEnum(AudienceIllustration),
  audienceBlocks: zod
    .array(
      zod.object({
        audienceBlockId: zod.number().optional().readonly(),
        title: zod.string(),
        subtitle: zod.string(),
        icon: zod.nativeEnum(AudienceBlockIcon),
        url: zod
          .string()
          .transform((value) => {
            if (
              value &&
              !value.startsWith('http://') &&
              !value.startsWith('https://')
            ) {
              return `https://${value}`;
            }

            return value;
          })
          .pipe(zod.string().url().optional().or(zod.literal('')))
          .nullish(),
        _destroy: zod.boolean().optional(),
      }),
    )
    .max(3)
    .optional(),
});
