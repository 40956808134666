import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { Wrapper, AudienceForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  useAudienceSuspense,
  useUpdateAudience,
  getAudienceQueryKey,
  getAudiencesQueryKey,
  getAudienceSuspenseQueryOptions,
} from '@/api';
import { audienceSchema } from '@/schemas';

export const Route = createFileRoute(
  '/_authenticated/audiences/$audienceId/edit',
)({
  loader: async ({ context: { queryClient }, params: { audienceId } }) =>
    queryClient.ensureQueryData(
      getAudienceSuspenseQueryOptions(Number(audienceId)),
    ),
  component: EditAudience,
});

function EditAudience() {
  const audienceId = Route.useParams({
    select: ({ audienceId }) => Number(audienceId),
  });
  const { data: audience } = useAudienceSuspense(audienceId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdateAudience({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getAudiencesQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getAudienceQueryKey(audienceId),
        });
      },
    },
  });

  const methods = useForm<zod.infer<typeof audienceSchema>>({
    resolver: zodResolver(audienceSchema),
    defaultValues: {
      ...audience,
      audienceBlocks: audience.audienceBlocks?.map((audienceBlock) => ({
        ...audienceBlock,
        audienceBlockId: audienceBlock.id,
      })),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: audience.id,
        data: {
          ...data,
          audienceBlocks: data.audienceBlocks?.map((audienceBlock) => ({
            id: audienceBlock.audienceBlockId,
            title: audienceBlock.title,
            subtitle: audienceBlock.subtitle,
            icon: audienceBlock.icon,
            url: audienceBlock.url,
            _destroy: audienceBlock._destroy,
          })),
        },
      });
      methods.reset({
        ...response,
        audienceBlocks: response.audienceBlocks?.map((audienceBlock) => ({
          ...audienceBlock,
          audienceBlockId: audienceBlock.id,
        })),
      });
      toast.success(<Trans>Audience updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper header={<Trans>Edit audience</Trans>} backLink='/audiences'>
      <FormProvider {...methods}>
        <AudienceForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
